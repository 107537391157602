<template>
    <v-dialog v-model="dialog" @keydown.esc="cancel">
        <v-card>
            <v-card-title class="text-subtitle-1 grey lighten-2">
                Form validation failed
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="text-body-1 mt-3">
                <v-card-text v-show="!!message">{{ message }}</v-card-text>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn
                    class="pink mx-auto"
                    elevation="0"
                    dark
                    @click.native="ok"
                    >OK</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data: () => ({
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
    }),
    methods: {
        open(message) {
            console.log("open", message);
            this.dialog = true;
            this.message = message;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        ok() {
            this.resolve(true);
            this.dialog = false;
        },
    },
};
</script>
