<template>
    <div class="text-center">
        <v-dialog
            v-model="profileDialog"
            :fullscreen="isMobile"
            max-width="650"
            rounded="lg"
            scrollable
            persistent
        >
            <v-card rounded="lg" elevation="0">
                <VtxToolbar
                    :title="$t('your_profile')"
                    @close="profileDialog = false"
                    :color="toolbar_color"
                />

                <v-stepper v-model="stepper" class="stepper">
                    <v-stepper-header v-if="!is_basic">
                        <v-stepper-step
                            editable
                            :complete="stepper > 1"
                            step="1"
                        >
                            {{ $t("general") }}
                        </v-stepper-step>
                        <v-divider></v-divider>

                        <v-stepper-step
                            editable
                            :complete="stepper > 2"
                            step="2"
                        >
                            {{ $t("address") }}
                        </v-stepper-step>
                        <v-divider></v-divider>

                        <v-stepper-step editable step="3">
                            {{ $t("identity") }}
                        </v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items v-if="!is_basic">
                        <v-form
                            v-model="valid"
                            ref="form"
                            :disabled="valdation_pending"
                        >
                            <v-stepper-content step="1">
                                <v-row class="mt-0" align="center">
                                    <v-col cols="12">
                                        <v-select
                                            v-model="current.type"
                                            filled
                                            dense
                                            hide-details
                                            :label="$t('account_type')"
                                            :items="account_types"
                                            prepend-icon="mdi-handshake-outline"
                                            item-text="type"
                                            item-value="id"
                                            @change="hideShowFields"
                                            disabled
                                            required
                                        ></v-select>
                                    </v-col>

                                    <v-col cols="12">
                                        <vtx-form
                                            v-model="current.email"
                                            :label="$t('email')"
                                            disabled
                                            icon="mdi-at"
                                        ></vtx-form>
                                    </v-col>

                                    <v-col cols="8">
                                        <vtx-text-field-iban
                                            v-model="current.phone"
                                            :label="$t('phone')"
                                            :len="16"
                                            persistent-hint
                                            filled
                                            hide-details="auto"
                                            icon="mdi-phone"
                                            rules="phone_or_empty"
                                        />
                                    </v-col>

                                    <v-col cols="8">
                                        <vtx-text-field-iban
                                            v-model="current.given_name"
                                            :label="$t('given_name')"
                                            :len="60"
                                            icon="mdi-account-outline"
                                            rules="text_with_dash|required"
                                            filled
                                        >
                                        </vtx-text-field-iban>
                                    </v-col>
                                    <v-col cols="8">
                                        <vtx-text-field-iban
                                            v-model="current.last_name"
                                            :label="$t('last_name')"
                                            :len="60"
                                            icon="mdi-account-outline"
                                            rules="text_with_dash|required"
                                            filled
                                        >
                                        </vtx-text-field-iban>
                                    </v-col>
                                    <v-col cols="12" class="text-center">
                                        <v-btn
                                            text
                                            class="mr-2"
                                            @click="cancel()"
                                            elevation="0"
                                            rounded
                                        >
                                            {{ $t("cancel") }}
                                        </v-btn>

                                        <v-btn
                                            v-if="!is_basic"
                                            color="primary"
                                            @click="stepper = 2"
                                            elevation="0"
                                            rounded
                                        >
                                            <span>Continue</span>
                                        </v-btn>
                                        <v-btn
                                            v-if="is_basic"
                                            color="primary"
                                            @click="update"
                                            elevation="0"
                                            rounded
                                        >
                                            <span>{{ $t("save") }}</span>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content editable step="2">
                                <v-row class="mt-0">
                                    <v-col cols="12">
                                        <vtx-text-field-iban
                                            v-model="current.address_line1"
                                            :label="$t('address_line1')"
                                            :len="50"
                                            icon="mdi-map-marker-outline"
                                            rules="text_with_numbers|required"
                                            filled
                                        >
                                        </vtx-text-field-iban>
                                    </v-col>
                                    <v-col cols="12">
                                        <vtx-text-field-iban
                                            v-model="current.address_line2"
                                            :label="$t('address_line2')"
                                            :len="50"
                                            icon="mdi-map-marker-outline"
                                            rules="text_with_numbers"
                                            filled
                                        >
                                        </vtx-text-field-iban>
                                    </v-col>
                                    <v-col cols="6">
                                        <vtx-text-field-iban
                                            v-model="current.address_city"
                                            :label="$t('city')"
                                            icon="mdi-map"
                                            :len="40"
                                            rules="text|required"
                                            filled
                                        >
                                        </vtx-text-field-iban>
                                    </v-col>
                                    <v-col cols="4">
                                        <vtx-text-field-iban
                                            v-model="current.address_postalcode"
                                            :label="$t('postalcode')"
                                            rules="postal_code"
                                            :len="15"
                                            filled
                                        ></vtx-text-field-iban>
                                    </v-col>
                                    <v-col cols="12">
                                        <vtx-country
                                            v-model="current.address_country"
                                            :label="$t('country')"
                                            icon="mdi-flag-outline"
                                            rules="required"
                                        />
                                    </v-col>

                                    <v-col cols="12" class="text-center">
                                        <v-btn
                                            text
                                            class="mr-2"
                                            @click="cancel()"
                                            elevation="0"
                                            rounded
                                        >
                                            {{ $t("cancel") }}
                                        </v-btn>

                                        <v-btn
                                            color="primary"
                                            @click="stepper = 3"
                                            elevation="0"
                                            rounded
                                        >
                                            Continue
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content step="3">
                                <v-row class="mt-0">
                                    <v-col cols="6">
                                        <vtx-country
                                            v-model="current.nationality"
                                            :label="$t('nationality')"
                                            icon="mdi-flag-outline"
                                            rules="required"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-menu
                                            v-model="menu2"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    v-model="current.birth_date"
                                                    :label="birth_date_label"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    filled
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="current.birth_date"
                                                @input="menu2 = false"
                                                color="pink"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12">
                                        <vtx-idinfo />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select
                                            v-model="current.idtype"
                                            filled
                                            :label="$t('idtype')"
                                            :items="id_types"
                                            prepend-icon="mdi-id-card"
                                            item-text="type"
                                            item-value="id"
                                            hide-details
                                        ></v-select>

                                        <v-radio-group
                                            v-model="image_or_pdf"
                                            row
                                            hide-details
                                            prepend-icon="mdi-image-filter-dramao-outline"
                                        >
                                            <v-radio
                                                :label="
                                                    $t('im_providing_photos')
                                                "
                                                value="img"
                                            ></v-radio>
                                            <v-radio
                                                :label="
                                                    $t('im_providing_scans')
                                                "
                                                value="pdf"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <v-col cols="6">
                                        <vtx-docupload
                                            v-if="image_or_pdf == 'img'"
                                            @reload="
                                                (img) =>
                                                    (current.kycfile1 = img)
                                            "
                                            caption="Upload front side"
                                            front="true"
                                        />
                                        <v-file-input
                                            v-if="image_or_pdf == 'pdf'"
                                            label="Front document side"
                                            v-model="current.kycfile1"
                                            dense
                                        ></v-file-input>
                                    </v-col>
                                    <v-col cols="6">
                                        <vtx-docupload
                                            v-if="
                                                image_or_pdf == 'img' &&
                                                current.idtype != 'PAS'
                                            "
                                            @reload="
                                                (img) =>
                                                    (current.kycfile2 = img)
                                            "
                                            caption="Upload front side"
                                        />
                                        <v-file-input
                                            v-if="
                                                image_or_pdf == 'pdf' &&
                                                current.idtype != 'PAS'
                                            "
                                            label="Back document side"
                                            v-model="current.kycfile2"
                                            dense
                                        >
                                        </v-file-input>
                                    </v-col>
                                    <v-col cols="12" class="text-center">
                                        <v-btn
                                            text
                                            class="mr-2"
                                            @click="cancel()"
                                            elevation="0"
                                            rounded
                                        >
                                            {{ $t("cancel") }}
                                        </v-btn>

                                        <v-btn
                                            class="pink"
                                            dark
                                            @click="updateAndValidate"
                                            v-if="!valdation_pending"
                                            elevation="0"
                                            rounded
                                        >
                                            {{ $t("save_and_validate") }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-form>
                    </v-stepper-items>
                    <v-stepper-items v-if="is_basic">
                        <v-form v-model="valid" ref="form">
                            <v-stepper-content step="1">
                                <v-row class="mt-0" align="center">
                                    <v-col cols="12">
                                        <v-select
                                            v-model="current.type"
                                            filled
                                            dense
                                            hide-details
                                            :label="$t('account_type')"
                                            :items="account_types"
                                            prepend-icon="mdi-handshake-outline"
                                            item-text="type"
                                            item-value="id"
                                            @change="hideShowFields"
                                            disabled
                                            required
                                        ></v-select>
                                    </v-col>

                                    <v-col cols="12">
                                        <vtx-text-field-iban
                                            v-model="current.email"
                                            :label="$t('email')"
                                            disabled
                                            icon="mdi-at"
                                        ></vtx-text-field-iban>
                                    </v-col>

                                    <v-col cols="8">
                                        <vtx-text-field-iban
                                            v-model="current.phone"
                                            :label="$t('phone')"
                                            :len="16"
                                            persistent-hint
                                            filled
                                            hide-details="auto"
                                            icon="mdi-phone"
                                            rules="phone_or_empty"
                                        />
                                    </v-col>

                                    <v-col cols="8">
                                        <vtx-text-field-iban
                                            v-model="current.given_name"
                                            :label="$t('given_name')"
                                            :len="60"
                                            icon="mdi-account-outline"
                                            rules="required|text_with_dash"
                                            filled
                                        >
                                        </vtx-text-field-iban>
                                    </v-col>
                                    <v-col cols="8">
                                        <vtx-text-field-iban
                                            v-model="current.last_name"
                                            :label="$t('last_name')"
                                            icon="mdi-account-outline"
                                            :len="60"
                                            rules="required|text_with_dash"
                                            filled
                                        >
                                        </vtx-text-field-iban>
                                    </v-col>
                                    <v-col cols="12" class="text-center">
                                        <v-btn
                                            text
                                            class="mr-2"
                                            @click="cancel()"
                                            elevation="0"
                                            rounded
                                        >
                                            {{ $t("cancel") }}
                                        </v-btn>

                                        <v-btn
                                            v-if="is_basic"
                                            color="primary"
                                            @click="update"
                                            elevation="0"
                                            rounded
                                        >
                                            {{ $t("save") }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-form>
                    </v-stepper-items>
                </v-stepper>
                <v-toolbar
                    elevation="0"
                    class="px-2 green"
                    dark
                    dense
                    v-if="current.kyc_status == 'KYC_SUCCEEDED'"
                >
                    <v-spacer></v-spacer>
                    <v-toolbar-title
                        ><span class="text-uppercase text-secondary overline"
                            >{{ $t("kyc_validated") }}
                        </span></v-toolbar-title
                    >

                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-toolbar
                    elevation="0"
                    class="px-2"
                    dark
                    dense
                    v-if="valdation_pending"
                >
                    <v-spacer></v-spacer>
                    <v-toolbar-title
                        ><span class="text-uppercase text-secondary overline"
                            >{{ $t("kyc_validation_pending") }}
                        </span></v-toolbar-title
                    >

                    <v-spacer></v-spacer>
                </v-toolbar>
            </v-card>
        </v-dialog>
        <vtx-info ref="confirm" />
    </div>
</template>

<script>
import VtxForm from "@/components/comp-vtx-form";

import VtxTextFieldIban from "@/components/bits/comp-vtx-text-field-iban";
import VtxCountry from "@/components/comp-vtx-country";
import VtxDocupload from "@/components/comp-vtx-docupload";
import VtxIdinfo from "@/components/bits/bits-vtx-idinfo";
import VtxInfo from "@/components/bits/bits-vtx-info";
import VtxToolbar from "@/components/bits/bits-vtx-toolbar";

import { ACCOUNTTYPES_PL, ACCOUNTTYPES_EN } from "@/localconst";
import { IDTYPES_EN, IDTYPES_PL } from "@/localconst";

import { mapState } from "vuex";
import moment from "moment";

export default {
    components: {
        VtxForm,
        VtxCountry,
        VtxDocupload,
        VtxIdinfo,
        VtxTextFieldIban,
        VtxInfo,
        VtxToolbar,
    },
    props: ["value"],
    data() {
        return {
            isBusinessAccount: false,
            valid: true,
            tabs: null,
            image_or_pdf: "image",
            alert: false,
            menu2: false,
            stepper: 1,
        };
    },
    watch: {
        profileDialog(val) {
            if (val) {
                this.run();
                this.$nextTick(() => {
                    this.$refs.form.reset();
                    this.$refs.form.resetValidation();
                });
            }
        },
    },
    beforeMount() {
        var locale = window.navigator.userLanguage || window.navigator.language;
        moment.locale(locale);
    },

    mounted() {
        this.$root.$confirm = this.$refs.confirm.open;
    },

    computed: {
        ...mapState("participants", ["current"]),

        account_types() {
            return this.$i18n.locale === "en"
                ? ACCOUNTTYPES_EN
                : ACCOUNTTYPES_PL;
        },

        id_types() {
            return this.$i18n.locale === "en" ? IDTYPES_EN : IDTYPES_PL;
        },
        isMobile() {
            return (
                this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.sm
            );
        },
        profileDialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        birth_date_label() {
            return this.$t("birth_date");
        },
        is_basic() {
            console.log(this.current);
            return !this.current.email_verified;
        },
        valdation_pending() {
            return (
                this.current.kyc_status === "KYC_VALIDATION_ASKED" ||
                this.current.kyc_status === "VALIDATION_ASKED"
            );
        },
        toolbar_color() {
            return this.valdation_pending ? "grey" : "pink";
        },
    },

    methods: {
        async run() {
            this.stepper = 1;
            this.valid = true;
            await this.$store
                .dispatch("participants/getCurrent", {
                    isFull: true,
                })
                .then(() => {});
        },

        closeDialog() {
            this.alert = false;
            this.profileDialog = false;
        },

        hideShowFields() {
            this.isBusinessAccount = this.current.type == 2;
        },

        async update() {
            if (this.$refs.form.validate()) {
                this.$store
                    .dispatch("participants/setCurrent", this.current)
                    .then(() => {
                        this.$store.dispatch(
                            "settings/setSnack",
                            this.$t("profile_updated")
                        );
                    });
                this.closeDialog();
            }
        },

        async updateAndValidate() {
            if (this.$refs.form.validate() && this.current.kycfile1) {
                this.$store.dispatch(
                    "participants/validateCurrent",
                    this.current
                );

                this.closeDialog();
            } else {
                this.alert = true;
                await this.$root.$confirm(
                    "Not all required fields are filled in properly"
                );
            }
        },

        cancel() {
            this.closeDialog();
        },
    },
};
</script>

<style scoped>
.stepper {
    height: 100%;
    overflow: visible;
}
</style>
