<template>
    <div class="text-center">
        <v-dialog
            v-model="termsDialog"
            persistent
            max-width="750"
            rounded="lg"
            scrollable
        >
            <v-card rounded="lg" elevation="0">
                <v-toolbar elevation="0" class="px-2 pink" dark>
                    <v-toolbar-title>
                        {{ $t("payments_terms") }}
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <div class="pa-3">
                        These Terms and Conditions govern the use of the OPTO
                        platform by natural and legal persons, both Italian and
                        foreign, who request registration on the platform and
                        wish to use an Electronic Money (E-Money) Payment
                        Account (Wallet) as a means of payment. The “Mangopay
                        Payment Services Framework Contract" is an integral part
                        of these Terms and Conditions. By registering with OPTO,
                        the User accepts and agrees to comply with all the
                        contractual conditions specified and/or referred to
                        herein.<br /><br />
                        <b>1. Definitions</b><br /><br />
                        For the purposes of these Terms and Conditions, the
                        terms listed below have the following meanings:<br /><br />
                        <b>Beneficiary:</b> The company which manages the sale
                        of food and non-food products through automatic
                        distributors (vending machines), registered with OPTO,
                        which may be designated from time to time by the
                        Customer and to which e-money is transferred in the form
                        of a payment transaction. <br /><br /><b
                            >Login credentials: </b
                        >username (valid email address) and keyword (Password)
                        chosen by the User at the time of registration with
                        OPTO. <br /><br />
                        <b>Enabled Device:</b> smartphones equipped with the
                        operating system Android version 5.0 and above or the
                        operating system IOS version 7.1.2 and above and enabled
                        for Internet browsing via the cellular data network
                        and/or Wi-Fi technology. <br /><br />
                        <b>Issuer or Mangopay: </b> Mangopay S.A., a company
                        incorporated under Luxembourg law, registered in the
                        Luxembourg Commercial and Corporate Registry under
                        number B173459, authorized to issue e-money by the
                        Luxembourg Commission for the Supervision of the
                        Financial Sector. <br /><br />
                        <b>Electronic Money (E-Money): </b>the monetary value
                        available at any given time in the User's Wallet, which
                        represents a credit claimed by the latter against the
                        Issuer, in accordance with the provisions of the
                        "Mangopay Payment Services Framework Contract".
                    </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn text @click="acceptTerms">
                        {{ $t("accept") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    components: {},
    props: ["value"],
    data() {
        return {};
    },

    computed: {
        termsDialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },

    methods: {
        acceptTerms() {
            this.$store.dispatch("participants/acceptTerms").then(() => {
                this.termsDialog = false;
            });
        },
    },
};
</script>
