const ACCOUNTTYPES_EN = [
    {
        type: "Individual",
        id: 1,
    },
    {
        type: "Business",
        id: 2,
    },
];

const ACCOUNTTYPES_PL = [
    {
        type: "Osobiste",
        id: 1,
    },
    {
        type: "Biznesowe",
        id: 2,
    },
];

const IDTYPES_EN = [
    {
        type: "National ID Card",
        id: "NID",
    },
    {
        type: "Passport",
        id: "PAS",
    },
    {
        type: "Driving License",
        id: "DRL",
    },
    {
        type: "Residence Permit",
        id: "RSP",
    },
];

const IDTYPES_PL = [
    {
        type: "Dowód osobisty",
        id: "NID",
    },
    {
        type: "Paszport",
        id: "PAS",
    },
    {
        type: "Prawo jazdy",
        id: "DRL",
    },
    {
        type: "Uprawnienia do pobytu",
        id: "RSP",
    },
];

const BANK_ACCOUNTTYPES_EN = [
    {
        type: "IBAN",
        id: "IBAN",
    },
    {
        type: "Domestic",
        id: "DOM",
    },
];

const BANK_ACCOUNTTYPES_PL = [
    {
        type: "IBAN",
        id: "NID",
    },
    {
        type: "Krajowe",
        id: "DOM",
    },
];

export {
    ACCOUNTTYPES_EN,
    ACCOUNTTYPES_PL,
    IDTYPES_EN,
    IDTYPES_PL,
    BANK_ACCOUNTTYPES_EN,
    BANK_ACCOUNTTYPES_PL,
};
