<template>
    <div>
        <v-icon class="float-left mr-2"
            >mdi-card-account-details-outline
        </v-icon>
        <div>
            <v-img
                v-if="image.src"
                :src="image.src"
                max-width="250px"
                contain
                class="mr-2"
                @click="openFileInputDialog"
                style="cursor: pointer"
            />
        </div>

        <input
            type="file"
            ref="fileInput"
            @change="loadImage($event)"
            accept="image/*"
            style="display: none"
        />

        <v-dialog v-model="showCroppingModal" max-width="700px">
            <v-card>
                <v-toolbar>Crop document from image</v-toolbar>

                <v-card-text class="mt-5">
                    <cropper
                        ref="cropper"
                        :src="image.src"
                        style="border: 1px solid #333333"
                    />
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="cropImage" text>Crop</v-btn>
                    <v-btn @click="showCroppingModal = false" text>
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

// This function is used to detect the actual image type,
function getMimeType(file, fallback = null) {
    const byteArray = new Uint8Array(file).subarray(0, 4);
    let header = "";
    for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16);
    }
    switch (header) {
        case "89504e47":
            return "image/png";
        case "47494638":
            return "image/gif";
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
            return "image/jpeg";
        default:
            return fallback;
    }
}

export default {
    components: {
        Cropper,
    },
    props: ["value", "caption", "front"],
    data() {
        return {
            showCroppingModal: false,

            image: {
                src: null,
                type: null,
            },
        };
    },
    created() {
        if (this.front) {
            this.image.src = require("../assets/id_front3.png");
        } else {
            this.image.src = require("../assets/id_back3.png");
        }
    },
    methods: {
        openFileInputDialog() {
            console.log("openFileInputDialog");
            this.$refs.fileInput.click();
        },
        cropImage() {
            const { canvas } = this.$refs.cropper.getResult();
            this.image.src = canvas.toDataURL();
            this.showCroppingModal = false;
            canvas.toBlob((blob) => {
                // console.log(blob);
                let file_name = "file_croped";
                if (this.front) {
                    file_name = file_name + "_front" + ".jpg";
                } else {
                    file_name = file_name + "_back" + ".jpg";
                }
                this.$emit("reload", new File([blob], file_name));
            }, this.image.type);
        },
        reset() {
            this.image = {
                src: null,
                type: null,
            };
        },
        readFileAsync(file) {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();

                const blob = URL.createObjectURL(file);

                reader.onload = (e) => {
                    this.image = {
                        src: blob,
                        type: getMimeType(e.target.result, file.type),
                    };
                    resolve(reader.result);
                };

                reader.onerror = reject;

                reader.readAsArrayBuffer(file);
            });
        },

        loadImage(event) {
            const { files } = event.target;

            if (files && files[0]) {
                if (this.image.src) {
                    URL.revokeObjectURL(this.image.src);
                }

                this.readFileAsync(files[0]).then(() => {
                    this.showCroppingModal = true;
                });
            }
        },
    },
    destroyed() {
        // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        console.log("destroyed");
        if (this.image.src) {
            URL.revokeObjectURL(this.image.src);
        }
    },
};
</script>
<style scoped>
.cropper {
    height: 400px;
    background: #ddd;
}
</style>
