import { sendApi } from "../common/helpers";

const state = {
    orders: [],
    ordersAuctions: [],
    ordersSum: [],
};

const mutations = {
    SET_ORDERS: (state, val) => {
        state.orders = val.orders;
        state.ordersAuctions = val.ordersAuctions;
        state.ordersSum = val.ordersSum;
    },
};

const actions = {
    async getOrders({ commit }) {
        let url = "/bids/orders";
        await sendApi(url, "get").then((response) => {
            commit("SET_ORDERS", response.data);
        });
    },
};

export default {
    namespaced: true,
    name: "orders",
    state,
    actions,
    mutations,
};
