const axios = require("axios");
const axiosRetry = require("axios-retry");
const { i18n } = require("@/plugins/i18n");

var self = (module.exports = {
    isMobile: (t) => {
        return t.$vuetify.breakpoint.xsOnly || t.$vuetify.breakpoint.sm;
    },
    lineColorHelper: (status) => {
        switch (status) {
            case "A":
                return "#d200df";
            case "Z":
                return "#999999";

            default:
                return "#517EF4";
        }
    },
    transactionStatusHelper: (status) => {
        switch (status) {
            case "disabled_mean_of_payment_for_currency":
                return i18n.t("disabled_mean_of_payment_for_currency");
            case "insufficient_funds":
                return i18n.t("insufficient_funds");
            case "insufficient_funds_for_fees":
                return i18n.t("insufficient_funds_for_fees");
            case "CREATED":
                return i18n.t("transaction_created");
            case "001011":
                return i18n.t("max_amount_exceeded");
            case "001012":
                return i18n.t("max_amount_to_low");
            default:
                return status;
        }
    },
    toCurrencyHelper: (v, c) => {
        if (typeof v !== "number") {
            return v;
        }
        var formatter = new Intl.NumberFormat(this.loc, {
            style: "currency",
            currency: c,
        });
        return formatter.format(v);
    },
    currencies: ["USD", "EUR", "PLN"],
    sendApi: (url, method, data, token, contentType) => {
        let headers = {};

        if (token) {
            headers["Authorization"] = `Bearer ${token}`;
        }

        if (contentType === "form") {
            headers["Content-Type"] = "application/form-data";
        } else {
            headers["Content-Type"] = "application/json";
        }

        // workaround for loading wallet data on page refresh

        if (method === "get") {
            axiosRetry(axios, { retries: 3 });
        }

        console.debug("sendApi", url, method, data, token);

        const axiosSet = {
            method: method,
            url: url,
            data: data,
            headers: headers,
        };
        return axios(axiosSet);
    },
    redirectToAssetExplorer(addr) {
        window.location.href = `https://testnet.algoexplorer.io/asset/${addr}`;
    },
    shortenString(inputString, keepCount = 6) {
        if (inputString.length <= keepCount * 2) {
            return inputString;
        }

        const startChars = inputString.slice(0, keepCount);
        const endChars = inputString.slice(-keepCount);
        const middleDots = "…";

        return `${startChars}${middleDots}${endChars}`;
    },
    getDescrDetails(JSONdescription, attrib = null, short = false) {
        try {
            let description = JSON.parse(JSONdescription);
            if (attrib) {
                if (short) {
                    return self.shortenString(description[attrib]);
                } else {
                    return description[attrib];
                }
            } else {
                return description;
            }
        } catch (error) {
            return "";
        }
    },
    fullStatus(sts) {
        switch (sts) {
            case "BID":
                return {
                    status: i18n.t("bid_bid"),
                    color: "green_status",
                    fullDescription: i18n.t("bid_bid_full"),
                };
            case "INV":
                return {
                    status: i18n.t("bid_invalid"),
                    color: "red_status",
                    fullDescription: i18n.t("bid_invalid_full"),
                };
            case "NSB":
                return {
                    status: i18n.t("no_enough_bids"),
                    color: "red_status",
                    fullDescription: i18n.t("no_enough_bids_full"),
                };
            case "ACC":
                return {
                    status: i18n.t("bid_accepted"),
                    color: "green_status",
                    fullDescription: i18n.t("bid_accepted_full"),
                };
            case "CCL":
                return {
                    status: i18n.t("bid_canceled"),
                    color: "red_status",
                    fullDescription: i18n.t("bid_canceled_full"),
                };
            case "NOF":
                return {
                    status: i18n.t("bid_no_funds"),
                    color: "red_status",
                    fullDescription: i18n.t("bid_no_funds_full"),
                };
            case "TKN":
                return {
                    status: i18n.t("bid_tokenized"),
                    color: "green_status",
                    fullDescription: i18n.t("bid_tokenize_full"),
                };
            case "STL":
                return {
                    status: i18n.t("bid_settled"),
                    color: "green_status",
                    fullDescription: i18n.t("bid_settled_full"),
                };
        }
    },
});
