<template>
    <div class="text-center">
        <v-dialog
            v-model="profileDialog"
            :fullscreen="isMobile"
            max-width="650"
            transition="dialog-bottom-transition"
            rounded="lg"
            scrollable
            persistent
        >
            <v-card rounded="lg" elevation="0">
                <v-toolbar elevation="0" class="px-2 pink" dark dense>
                    <v-toolbar-title>
                        {{ $t("payout") }}
                    </v-toolbar-title>
                    <v-spacer />
                    <v-btn
                        icon
                        class="mr"
                        @click="profileDialog = false"
                        elevation="0"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-form v-model="valid" ref="form">
                        <v-tabs v-model="tabs">
                            <v-tab> {{ $t("bank_account") }} </v-tab>
                            <v-tabs-items v-model="tabs">
                                <v-tab-item>
                                    <v-row class="my-2">
                                        <v-col cols="12">
                                            <vtx-text-field-iban
                                                v-model="bankAccount.bank_iban"
                                                label="IBAN"
                                                icon="mdi-map-marker-outline"
                                                rules="required|iban"
                                            ></vtx-text-field-iban>
                                        </v-col>

                                        <v-col cols="12">
                                            <vtx-text-field-iban
                                                v-model="bankAccount.bank_bic"
                                                label="BIC"
                                                icon="mdi-map-marker-outline"
                                                rules="bic_or_empty"
                                            ></vtx-text-field-iban>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-tabs>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn text @click="cancel()">
                        {{ $t("cancel") }}
                    </v-btn>
                    <v-btn text @click="update">
                        {{ $t("save") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import VtxTextFieldIban from "@/components/bits/comp-vtx-text-field-iban";

import { mapState } from "vuex";

export default {
    components: { VtxTextFieldIban },
    props: ["value"],
    data() {
        return {
            valid: false,
            tabs: null,
        };
    },

    watch: {
        // https://stackoverflow.com/questions/63729346/how-to-run-api-calls-in-vuetify-dialog-only-when-the-dialog-is-shown
        profileDialog(newVal) {
            if (newVal) {
                this.$store.dispatch("participants/getCurrentBankAccount");
            }
        },
    },

    computed: {
        ...mapState("participants", ["bankAccount"]),
        ...mapState("settings", ["loading"]),

        isMobile() {
            return (
                this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.sm
            );
        },
        profileDialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },

    methods: {
        async update() {
            if (this.$refs.form.validate()) {
                this.$store.dispatch("settings/setLoading", true, {
                    root: true,
                });
                this.$store
                    .dispatch(
                        "participants/setCurrentBankAccount",
                        this.bankAccount
                    )
                    .then(() => {
                        this.$store.dispatch("settings/setLoading", false, {
                            root: true,
                        });
                    });

                this.profileDialog = false;
            }
        },

        async cancel() {
            this.profileDialog = false;
        },
    },
};
</script>

<style scoped></style>
