<template>
    <v-row class="ma-0">
        <v-col lg="6" xl="6" md="6" xs="12" cols="12">
            <v-card
                height="100%"
                color="#F5F5F5"
                class="align-self-center elevation-0"
            >
                <v-card-title class="justify-center pb-2">
                    <v-icon large>mdi-clock-end</v-icon>
                </v-card-title>
                <v-card-text class="text-center">
                    {{ $t("funding_ends") }}
                    {{ getFdate(selected.endsAt) }}
                </v-card-text>
            </v-card>
        </v-col>
        <v-col lg="6" xl="6" xs="12" md="6" cols="12">
            <v-card
                height="100%"
                color="#F5F5F5"
                class="align-self-center elevation-0"
            >
                <v-card-title class="justify-center pb-2">
                    <v-icon large>mdi-gavel</v-icon>
                </v-card-title>
                <v-card-text class="text-center">
                    {{ $t("auction_date") }}
                    {{ getFdate(selected.auctionDateTime) }}
                </v-card-text>
            </v-card>
        </v-col>
        <v-col lg="6" xl="6" xs="12" md="6" cols="12">
            <v-card
                height="100%"
                color="#F5F5F5"
                class="align-self-center elevation-0"
            >
                <v-card-title class="justify-center pb-2">
                    <v-icon large>mdi-counter</v-icon>
                </v-card-title>
                <v-card-text class="text-center">
                    {{ $t("tokens_quantity") }}

                    <v-tooltip bottom max-width="300">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon small v-bind="attrs" v-on="on">
                                mdi-information-outline
                            </v-icon>
                        </template>
                        <span>{{ $t("tokens_quantity_icon") }}</span>
                    </v-tooltip>
                    {{ selected.quantity }}
                </v-card-text>
            </v-card>
        </v-col>
        <v-col lg="6" xl="6" xs="12" md="6" cols="12">
            <v-card
                height="100%"
                color="#F5F5F5"
                class="align-self-center elevation-0"
            >
                <v-card-title class="justify-center pb-2">
                    <v-img
                        :aspect-ratio="4 / 3"
                        src="@/assets/algorand_small.png"
                        contain
                        style="filter: opacity(0.8)"
                        max-height="33px"
                    ></v-img>
                </v-card-title>
                <v-card-text class="text-center">
                    {{ $t("secured_in") }} Algorand
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: ["value", "selected"],
    computed: {
        getFdate() {
            return (v) => {
                let date = new Date(v);
                return date.toLocaleDateString(this.loc);
            };
        },
    },
};
</script>
```
