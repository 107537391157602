<template>
    <v-dialog
        v-model="dialog"
        width="auto"
        :fullscreen="$vuetify.breakpoint.xsOnly"
    >
        <v-card>
            <v-card-title class="text-h5">
                {{ $t("marketplace_available_soon") }}
            </v-card-title>
            <v-card-text>{{ $t("arternity_marketplace") }} </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn elevation="0" @click="dialog = false"> Ok </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ["value"],
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
};
</script>
