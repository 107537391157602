<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-data-table
                    :headers="headers"
                    :items="messages"
                    class="elevation-0 ma-3"
                    single-expand
                    show-expand
                    :expanded.sync="expanded"
                    height="500px"
                >
                    <template v-slot:[`item.messageDT`]="{ item }">
                        {{
                            new Date(item.messageDT)
                                | dateFormat("YYYY.MM.DD HH:mm")
                        }}
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">{{ item.message }}</td>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "view-orders",

    data() {
        return {
            expanded: [],
            loc: "en-gb",
            titled: false,
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            pagination: {},

            totalItems: 0,
            options: {
                page: 1,
                itemsPerPage: 10,
            },
        };
    },

    created() {
        this.getMessages();
    },

    computed: {
        ...mapState("messages", ["messages"]),

        headers() {
            return [
                {
                    text: this.$t("date"),
                    align: "left",
                    value: "messageDT",
                    width: "15%",
                    sortable: true,
                },
                {
                    text: this.$t("Message"),
                    align: "left",
                    value: "topic",
                    width: "50%",
                    sortable: true,
                },
                {
                    text: "",
                    align: "right",
                    value: "data-table-expand",
                },
            ];
        },

        footerProps() {
            let props = {
                "items-per-page-text": this.$t("rows_per_page"),
                "items-per-page-options": [5, 10],
            };
            return props;
        },
    },
    methods: {
        async getMessages() {
            await this.$store.dispatch("messages/getMessages", {
                full: true,
            });
        },
    },
};
</script>

<style>
.v-data-table__expanded.v-data-table__expanded__content {
    box-shadow: none !important;
}
</style>
