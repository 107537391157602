import { sendApi } from "../common/helpers";

const state = {
    participant: [],
    current: [],
    token: null,
    bankAccount: {},
};

const mutations = {
    SET_CURRENT: (state, val) => {
        state.current = val;
    },
    SET_CURRENT_ACCOUNT: (state, val) => {
        state.bankAccount = val;
    },
    SET_CURRENT_TERMS: (state, val) => {
        state.current.mango_terms = val;
    },
};

const getters = {
    kycvalidated(state) {
        return state.current.mango_kyc == "REGULAR" ? true : false;
    },
    mangotermsaccepted(state) {
        return state.current.mango_terms;
    },
};

const actions = {
    async setParticipant({ commit }, payload) {
        const url = "/participants/profile";
        await sendApi(url, "post", payload)
            .then(() => {
                commit();
            })
            .catch((error) => {
                console.log(error);
                this.errored = true;
            });
    },

    async getCurrent({ commit, dispatch }, payload) {
        let url = "";

        if (payload && payload.isFull === true) {
            url = "/participants/profile?isFull=1";
        } else {
            url = "/participants/profile/";
        }
        await sendApi(url, "GET", null).then((response) => {
            dispatch("wallets/getWallets", null, { root: true });
            // [to be removed]
            console.log("------------ GET CURRENT PROFILE ------------");
            console.log(response.data);
            commit("SET_CURRENT", response.data);
        });
    },

    async setCurrent(_, payload) {
        return new Promise((resolve, reject) => {
            // remove unnecessary attributes begore sending to backend

            let apiPayload = {
                address_city: payload.address_city,
                address_country: payload.address_country,
                address_line1: payload.address_line1,
                address_line2: payload.address_line2,
                address_postalcode: payload.address_postalcode,
                birth_date: payload.birth_date,
                company_name: payload.company_name,
                idtype: payload.idtype,
                kycfile1: payload.kycfile1,
                kycfile2: payload.kycfile2,
                given_name: payload.given_name,
                last_name: payload.last_name,
                nationality: payload.nationality,
                phone: payload.phone,
            };

            const url_profile = "/participants/profile/";
            sendApi(url_profile, "patch", apiPayload)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
            // }
        });
    },

    async validateCurrent({ dispatch }, payload) {
        const apiPayload = {
            address_city: payload.address_city,
            address_country: payload.address_country,
            address_line1: payload.address_line1,
            address_line2: payload.address_line2,
            address_postalcode: payload.address_postalcode,
            birth_date: payload.birth_date,
            company_name: payload.company_name,
            idtype: payload.idtype,
            kycfile1: payload.kycfile1,
            kycfile2: payload.kycfile2,
            given_name: payload.given_name,
            last_name: payload.last_name,
            nationality: payload.nationality,
            phone: payload.phone,
        };

        const url_profile = "/participants/profile?validate=1";

        try {
            const profileUpdate = await sendApi(
                url_profile,
                "patch",
                apiPayload
            );

            if (profileUpdate) {
                const url_kyc = "/participants/profile/kyc/documents";
                const formData = new FormData();

                if (payload.kycfile1) {
                    formData.append("pages", payload.kycfile1);
                }

                if (payload.kycfile2) {
                    formData.append("pages", payload.kycfile2);
                }

                if (payload.kycfile1 || payload.kycfile2) {
                    await sendApi(url_kyc, "post", formData, null, "form");
                }
            }
        } catch (error) {
            dispatch("settings/setError", error.response.data.message, {
                root: true,
            });
        }
    },

    async getCurrentBankAccount({ commit }) {
        const url_profile_bankaccount = "/participants/profile/bankaccount/";
        await sendApi(url_profile_bankaccount, "get", {}).then(
            (res) => {
                commit("SET_CURRENT_ACCOUNT", {
                    bank_bic: res.data.bank_bic,
                    bank_iban: res.data.bank_iban,
                });
            },
            (error) => {
                console.log(error);
            }
        );
        // }
    },

    async acceptTerms({ rootState, commit }) {
        return new Promise((resolve, reject) => {
            let token = rootState.auth.token;
            if (token) {
                const url_profile_termsaccepted =
                    "/participants/profile/acceptterms/";
                sendApi(url_profile_termsaccepted, "patch", {}, token).then(
                    () => {
                        commit("SET_CURRENT_TERMS", true);
                        resolve();
                    },
                    (error) => {
                        reject(error);
                    }
                );
            }
        });
    },

    async resendVerificationEmail({ rootState }) {
        return new Promise((resolve, reject) => {
            let token = rootState.auth.token;
            if (token) {
                const url_profile_termsaccepted =
                    "/participants/profile/resendverificationemail/";
                sendApi(url_profile_termsaccepted, "get", {}, token).then(
                    () => {
                        resolve();
                    },
                    (error) => {
                        reject(error);
                    }
                );
            }
        });
    },
};

export default {
    namespaced: true,
    name: "participants",
    state,
    actions,
    mutations,
    getters,
};
