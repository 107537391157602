import { sendApi } from "../common/helpers";

const state = {
    wallets: [],
    groups: [],
    payurl: "",
    lastTransactionStatus: "",
    transactions: [],
    transactionsCount: 0,
    transactionsPages: 0,
};

const mutations = {
    SET_GROUPS: (state, val) => {
        state.groups = val;
    },
    SET_PAYURL: (state, val) => {
        state.payurl = val;
    },
    SET_WALLETS: (state, val) => {
        state.wallets = val;
    },
    SET_BLOCKED_FUNDS: (state, val) => {
        console.log(val);
    },
    SET_LAST_TRANSCATION_STATUS: (state, val) => {
        state.lastTransactionStatus = val;
    },
    SET_TRANSACTIONS: (state, val) => {
        // state.transactions = val.items;
        // https://stackoverflow.com/questions/50767191/vuex-update-an-entire-array
        state.transactions = [...val.items];
        state.transactionsCount = parseInt(val.count);
        state.transactionsPages = parseInt(val.pages);
    },
};

const actions = {
    async payIn(_, payload) {
        const url = `/payments/wallets/payin`;
        return new Promise((resolve, reject) => {
            sendApi(url, "post", payload).then(
                (response) => {
                    resolve(response.data);
                },
                (error) => {
                    reject(error.response.data);
                }
            );
        });
    },
    async payOut(_, payload) {
        return new Promise((resolve, reject) => {
            const url_profile_payout = `/payments/wallets/payout/`;
            sendApi(url_profile_payout, "post", payload).then(
                () => {
                    resolve();
                },
                (error) => {
                    reject(error);
                }
            );
        });
    },

    async getTransactionStatus(_, payload) {
        return new Promise((resolve, reject) => {
            const url = `/payments/transaction/status/${payload}`;
            sendApi(url, "get")
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getTransactions({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const url = `/payments/wallet/${payload.currency}?page=${payload.page}&perPage=10`;
            sendApi(url, "get")
                .then((response) => {
                    // console.log(response.data);
                    commit("SET_TRANSACTIONS", {
                        items: response.data.items,
                        count: response.data.count,
                        pages: response.data.pages,
                    });
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async getWallets({ commit }) {
        const url = `/payments/wallets`;
        await sendApi(url, "get").then((response) => {
            commit("SET_WALLETS", response.data.wallets);
            commit("SET_BLOCKED_FUNDS", response.data.blocked);
        });
    },
    async loadGroups({ commit }) {
        const url = `/payments/banks`;
        await sendApi(url, "get").then((response) => {
            commit("SET_GROUPS", response.data.BankGroups);
        });
    },

    async payment({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const url = `/payments`;
            sendApi(url, "post", payload)
                .then((response) => {
                    commit("SET_PAYURL", response.data.TransactionUrl);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

export default {
    namespaced: true,
    name: "wallets",
    state,
    actions,
    mutations,
};
