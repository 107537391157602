<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-data-table
                    :headers="headers"
                    :items="orders"
                    class="elevation-0 ma-3"
                    height="500px"
                    :no-data-text="no_data_text"
                >
                    <template v-slot:[`item.dateSend`]="{ item }">
                        {{
                            new Date(item.dateSend)
                                | dateFormat("YYYY.MM.DD HH:mm")
                        }}
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                        <vtx-status-chip
                            :status="item.status"
                        ></vtx-status-chip>
                    </template>

                    <template v-slot:[`item.auctionId`]="{ item }">
                        <a :href="getUrlForProject(item.auctionId)">
                            {{ lookUpProjectTitle(item.auctionId) }}</a
                        >
                    </template>

                    <template v-slot:[`item.totalForFractions`]="{ item }">
                        <span
                            v-if="item.status == 'INV'"
                            style="text-decoration: line-through"
                        >
                            {{
                                toCurrency(
                                    item.totalForFractions,
                                    item.currency
                                )
                            }}
                        </span>
                        <span v-else>
                            {{
                                toCurrency(
                                    item.totalForFractions,
                                    item.currency
                                )
                            }}
                        </span>
                    </template>

                    <template v-slot:[`item.totalPercentFeees`]="{ item }">
                        {{ item.totalPercentFeees }}
                    </template>
                    <template v-slot:[`item.totalFees`]="{ item }">
                        <span
                            v-if="item.status == 'INV'"
                            style="text-decoration: line-through"
                        >
                            {{ toCurrency(item.totalFees, item.currency) }}
                        </span>
                        <span v-else>
                            {{ toCurrency(item.totalFees, item.currency) }}
                        </span>
                    </template>

                    <template v-slot:[`item.givenFractions`]="{ item }">
                        <v-chip small v-if="item.givenFractions == 0">
                            {{ item.givenFractions }}
                        </v-chip>
                        <v-chip small v-else color="pink">
                            <span style="color: #fff">{{
                                item.givenFractions
                            }}</span>
                        </v-chip>
                    </template>

                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title style="text-transform: capitalize">
                                {{ $t("orders") }}
                            </v-toolbar-title>

                            <v-spacer></v-spacer>
                            <v-toolbar-title style="text-transform: capitalize">
                                {{ $t("Blocked_amount") }}
                            </v-toolbar-title>
                            <v-divider
                                class="ml-2 mr-2"
                                inset
                                vertical
                            ></v-divider>
                            <v-chip
                                class="ml-1"
                                label
                                v-if="ordersSum.EUR > 0"
                                >{{ toCurrency(ordersSum.EUR, "EUR") }}</v-chip
                            >
                            <v-chip
                                class="ml-1"
                                label
                                v-if="ordersSum.USD > 0"
                                >{{ toCurrency(ordersSum.USD, "USD") }}</v-chip
                            >
                            <v-chip
                                class="ml-1"
                                label
                                v-if="ordersSum.PLN > 0"
                                >{{ toCurrency(ordersSum.PLN, "PLN") }}</v-chip
                            >
                        </v-toolbar>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { toCurrencyHelper } from "@/common/helpers";
import VtxStatusChip from "@/components/bits/bits-vtx-status-chip.vue";

export default {
    components: { VtxStatusChip },
    name: "view-orders",

    data() {
        return {
            payoutDialog: false,
            payoutRecord: {},
            loc: "en-gb",
            titled: false,
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            pagination: {},

            totalItems: 0,
            options: {
                page: 1,
                itemsPerPage: 10,
            },
        };
    },

    created() {
        this.getOrders();
    },

    computed: {
        ...mapState("orders", ["orders", "ordersAuctions", "ordersSum"]),

        headers() {
            return [
                {
                    text: this.$t("date"),
                    align: "left",
                    value: "dateSend",
                    width: "150px",
                    sortable: true,
                },
                {
                    text: this.$t("Status"),
                    align: "left",
                    value: "status",
                    width: "120px",
                    sortable: true,
                },
                {
                    text: this.$t("project"),
                    align: "left",
                    value: "auctionId",
                    width: "40%",
                    sortable: true,
                },

                {
                    text: this.$t("total_for_shares"),
                    align: "right",
                    value: "totalForFractions",
                    width: "100px",
                    sortable: false,
                },
                {
                    text: this.$t("percent_fees"),
                    align: "right",
                    value: "totalPercentFees",
                    width: "100px",
                    sortable: false,
                },
                {
                    text: this.$t("Fees"),
                    align: "right",
                    value: "totalFees",
                    width: "120px",
                    sortable: false,
                },
                {
                    text: this.$t("fractions_count_short"),
                    align: "right",
                    value: "givenFractions",
                    width: "120px",
                    sortable: true,
                },
            ];
        },

        footerProps() {
            let props = {
                "items-per-page-text": this.$t("rows_per_page"),
                "items-per-page-options": [5, 10],
            };
            return props;
        },

        toCurrency() {
            return (v, c) => {
                return toCurrencyHelper(v, c);
            };
        },

        no_data_text() {
            return this.$t("no_data_text");
        },
    },
    methods: {
        ...mapActions({
            setLoading: "settings/setLoading",
        }),
        getUrlForProject: function (projectId) {
            return process.env.VUE_APP_URL + "/details/" + projectId;
        },
        lookUpProjectTitle(projectId) {
            let auction = this.ordersAuctions.find(
                (auction) => auction.id == projectId
            );
            if (auction) {
                return auction.title;
            }
            return "";
        },
        async getOrders() {
            this.setLoading(true);

            await this.$store
                .dispatch("orders/getOrders", {
                    page: this.options.page,
                })
                .then(() => {
                    this.setLoading(false);
                })
                .catch(() => {
                    this.setLoading(false);
                });
        },
    },
};
</script>

<style scoped>
.currency-select {
    font-size: smaller;
}
.theme--light.v-data-table {
    background-color: #fafafa;
    border-radius: 10px;
}
.theme--light.v-data-table-header {
    border-radius: 10px;
}
</style>
