<template>
    <v-text-field
        v-model="txtInput"
        :filled="filled"
        hide-details="auto"
        persistent-hint
        :prepend-icon="icon"
        :label="label"
        :disabled="disabled"
        :rules="stringRulesToArray"
        @keydown="validateInput"
        :maxlength="len"
    ></v-text-field>
</template>

<script>
import moment from "moment";

export default {
    name: "vtx-text-field-iban",
    props: {
        label: {
            type: String,
            default: "",
        },
        value: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            default: "",
        },
        rules: {
            type: String,
            default: "",
        },
        filled: {
            type: Boolean,
            default: false,
        },
        len: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            rulesdef: [],
            all_rules: [],
        };
    },

    beforeMount() {
        var locale = window.navigator.userLanguage || window.navigator.language;
        moment.locale(locale);

        this.rulesdef["required"] = (value) => !!value || this.$t("required");
        this.rulesdef["iban"] = (value) => {
            const pattern =
                //eslint-disable-next-line
                /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/;
            return pattern.test(value) || this.$t("invalid_iban");
        };
        this.rulesdef["bic_or_empty"] = (value) => {
            if (!value) return true;
            const pattern =
                //eslint-disable-next-line
                /^$|^[A-Z]{6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3})?$/;
            return pattern.test(value) || this.$t("invalid_bic");
        };
        this.rulesdef["date"] = (value) => {
            if (!value) return true;
            const current_format = moment.localeData().longDateFormat("L");
            return moment(value, current_format, true).isValid();
        };
        this.rulesdef["text"] = (value) => {
            if (!value) return true;
            const pattern =
                //eslint-disable-next-line
                /^[\p{L}\s]+$/gu;
            return pattern.test(value) || this.$t("invalid_text");
        };
        this.rulesdef["text_with_numbers"] = (value) => {
            if (!value) return true;
            const pattern =
                //eslint-disable-next-line
                /^[\p{L}\s0-9\/\-\.]+$/gu;
            return pattern.test(value) || this.$t("invalid_text");
        };
        this.rulesdef["text_with_dash"] = (value) => {
            if (!value) return true;
            const pattern =
                //eslint-disable-next-line
                /^[\p{L}\s-]+(?:-[\p{L}\s-]+)*$/u;
            return pattern.test(value) || this.$t("invalid_text");
        };
        this.rulesdef["phone_or_empty"] = (value) => {
            if (!value) return true;
            const pattern =
                //eslint-disable-next-line
                /^\+[1-9]\d{10,14}$/;
            return pattern.test(value) || this.$t("invalid_phone");
        };
        this.rulesdef["postal_code"] = (value) => {
            const pattern =
                //eslint-disable-next-line
                /^[A-Za-z0-9\s-]+$/;
            return pattern.test(value) || this.$t("invalid_postal_code");
        };
    },

    created() {
        if (this.rules) {
            this.all_rules = this.rules.split("|").map((element) => {
                return element.trim();
            });
        }
    },

    computed: {
        txtInput: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },

        mutableTxtInput: {
            get() {
                return this.txtInput;
            },
        },

        stringRulesToArray() {
            if (this.rules) {
                // let all_rules = this.rules.split("|").map((element) => {
                //     return element.trim();
                // });
                let arr_rules = [];
                this.all_rules.forEach((element) => {
                    arr_rules.push(this.rulesdef[element]);
                });

                return arr_rules;
            }
            return [];
        },
    },
    methods: {
        validateInput(event) {
            if (this.all_rules.includes("phone_or_empty")) {
                this.validateNumericInput(event);
            }
        },
        validateNumericInput(event) {
            const keyCode = event.keyCode || event.which;
            //eslint-disable-next-line
            const numericRegex = /[0-9\+\s]/;

            if (keyCode !== 8 && !numericRegex.test(event.key)) {
                event.preventDefault();
            }
        },
    },
};
</script>
