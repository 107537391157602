<template>
    <v-card elevation="1" class="ml-8 pink lighten-1" dark>
        <v-card-text> {{ $t("you_need_to_provide_id") }} </v-card-text>
        <v-card-actions>
            <v-btn text @click="show_details = !show_details">
                {{ $t("accepted_documents") }}</v-btn
            >

            <v-spacer></v-spacer>
            <v-btn icon @click="show_details = !show_details">
                <v-icon>{{
                    show_details ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
            </v-btn>
        </v-card-actions>

        <v-expand-transition>
            <div v-show="show_details">
                <v-divider></v-divider>

                <v-card-text>
                    <v-row>
                        <v-col lg="4" xl="4" md="4" sm="6" xs="6">
                            <v-chip small>European economic</v-chip>
                        </v-col>
                        <v-col lg="8" xl="8" md="8" sm="6" xs="6">
                            {{ $t("international_passport") }},
                            {{ $t("driving_license") }},
                            {{ $t("national_id_card") }},
                            {{ $t("residence_permit") }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col lg="4" xl="4" md="4" sm="6" xs="6">
                            <v-chip small>Canada, UK, USA</v-chip>
                        </v-col>
                        <v-col lg="8" xl="8" md="8" sm="6" xs="6">
                            {{ $t("international_passport") }},
                            {{ $t("driving_license") }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col lg="4" xl="4" md="4" sm="6" xs="6">
                            <v-chip small>International</v-chip>
                        </v-col>
                        <v-col lg="8" xl="8" md="8" sm="6" xs="6">
                            {{ $t("international_passport") }}</v-col
                        >
                    </v-row>
                </v-card-text>
            </div>
        </v-expand-transition>
    </v-card>
</template>
<script>
export default {
    name: "vtx-idinfo",
    data: () => ({
        show_details: false,
    }),
};
</script>
