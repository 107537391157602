<template>
    <v-container fluid>
        <v-row>
            <v-col lg="12" xl="12" md="12" sm="12" xs="12" cols="12">
                <fullscreen v-model="fullscreen">
                    <v-img
                        v-show="fullscreen"
                        :aspect-ratio="4 / 3"
                        :src="selected.picture"
                    ></v-img>
                </fullscreen>

                <v-card class="mx-0 elevation-0" rounded="md">
                    <v-card-text class="mx-auto mt-0 pb-0">
                        <span class="text-h4 my-0 py-0 font-weight-bold">
                            {{ selected.title }}
                        </span>
                    </v-card-text>
                    <v-card-text ref="cardText" class="mx-auto mt-0 pt-2">
                        <span class="text-h5 my-0 font-weight-normal"
                            >{{ selected.authorName }} {{ selected.year }}</span
                        >
                    </v-card-text>

                    <v-divider class="mx-4"></v-divider>
                    <v-card-text>
                        <v-row class="d-flex mx-0 mt-0">
                            <v-col
                                xl="8"
                                lg="7"
                                md="7"
                                xs="12"
                                sm="12"
                                cols="12"
                            >
                                <v-hover
                                    v-slot="{ hover }"
                                    v-if="!isMobileScreen"
                                >
                                    <v-card>
                                        <v-img
                                            :aspect-ratio="4 / 3"
                                            :src="selected.picture"
                                            class="rounded-md elevation-0"
                                        ></v-img>
                                        <v-fade-transition>
                                            <v-overlay
                                                v-if="hover && !isMobileScreen"
                                                absolute
                                                color="#036358"
                                            >
                                                <v-btn
                                                    small
                                                    v-if="!isMobileScreen"
                                                    @click="toggleFullScreen"
                                                    >{{
                                                        $t("full_screen")
                                                    }}</v-btn
                                                >
                                            </v-overlay>
                                        </v-fade-transition>
                                    </v-card>
                                </v-hover>

                                <v-card v-if="isMobileScreen">
                                    <v-img
                                        :aspect-ratio="4 / 3"
                                        :src="selected.picture"
                                        class="rounded-md elevation-0"
                                    ></v-img>
                                </v-card>

                                <div
                                    class="mt-5 mb-2 text-subtitle-1 font-weight-medium text-uppercase"
                                >
                                    {{ $t("artwork_desciption") }}
                                </div>
                                <div class="my-2">
                                    {{ selected.description }}
                                </div>
                                <div
                                    class="my-2 text-subtitle-1 font-weight-medium text-uppercase"
                                >
                                    {{ $t("the_artist") }}
                                </div>
                                <div class="mt-2">
                                    {{ getAuthorDescription(selected) }}
                                </div>
                                <div
                                    v-if="selected.event"
                                    class="my-2 text-subtitle-1 font-weight-medium text-uppercase"
                                >
                                    {{ $t("auction_event") }}
                                </div>
                                <div
                                    class="mb-8 float-left"
                                    v-if="selected.event && selected.event.name"
                                >
                                    {{ selected.event.name }}<br />
                                    <span v-if="selected.event.partner">
                                        {{ selected.event.partner.name }} </span
                                    ><br />
                                    <a
                                        :href="selected.event.url"
                                        color="#d200df"
                                        rounded
                                        >Zobacz stronę wydarzenia</a
                                    >
                                </div>
                                <div v-if="!selected.event" class="my-10"></div>
                            </v-col>
                            <v-col
                                xl="4"
                                lg="5"
                                md="5"
                                xs="12"
                                sm="12"
                                class="py-0 px-0"
                            >
                                <vtx-project-details-headline
                                    :selected="selected"
                                />
                                <v-row>
                                    <v-col class="pa-6 pt-0 pb-6">
                                        <div
                                            class="text-subtitle-1 font-weight-medium text-uppercase"
                                        >
                                            {{ $t("min_price") }}
                                        </div>
                                        <div class="text-h4">
                                            <span style="color: #517ef4"
                                                >{{
                                                    toCurrency(
                                                        selected.minPrice,
                                                        selected.currency
                                                    )
                                                }}
                                            </span>
                                        </div>
                                        <div class="text-overline">
                                            {{ $t("price_estimation") }}:
                                            {{
                                                toCurrency(
                                                    selected.minEstPrice,
                                                    selected.currency
                                                )
                                            }}
                                            -
                                            {{
                                                toCurrency(
                                                    selected.maxEstPrice,
                                                    selected.currency
                                                )
                                            }}
                                        </div>
                                        <v-divider class="my-4"></v-divider>
                                        <div
                                            class="text-subtitle-1 font-weight-medium text-uppercase my-2"
                                        >
                                            {{ $t("funds_raised") }}
                                        </div>

                                        <v-progress-linear
                                            height="30"
                                            :value="selected.reached"
                                            rounded
                                            dark
                                            :color="lineColor(selected.status)"
                                            class="my-2 elevation-0"
                                        >
                                            <template
                                                v-slot:default="{ value }"
                                            >
                                                <strong>{{ value }}%</strong>
                                            </template>
                                        </v-progress-linear>

                                        <div
                                            class="font-weight-normal mb-3"
                                            v-if="selected.status == 'A'"
                                        >
                                            {{ $t("this_funding_ends_in") }}
                                            <time-counter
                                                :targetdate="selected.endsAt"
                                            />
                                        </div>

                                        <v-btn
                                            class="my-2 white--text"
                                            color="deep-purple accent-4"
                                            rounded
                                            elevation="0"
                                            @click.stop="buyDialog = true"
                                            :disabled="
                                                !canBid ||
                                                selected.status != 'A'
                                            "
                                        >
                                            {{ $t("reserve_a_slot") }}
                                        </v-btn>

                                        <v-btn
                                            class="my-2 ml-2"
                                            rounded
                                            outlined
                                            color="deep-purple accent-4"
                                            @click.stop="bidDialog = true"
                                            :disabled="!$auth.isAuthenticated"
                                        >
                                            {{ $t("view_slots") }}
                                        </v-btn>
                                        <v-divider class="my-4"></v-divider>

                                        <div
                                            class="my-2 text-subtitle-1 font-weight-medium text-uppercase"
                                        >
                                            {{ $t("fees") }}
                                        </div>
                                        <div class="mt-2">
                                            {{ $t("fees_total_desc") }}
                                            {{ selected.totalPercentFees }} %
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <vtx-buy-shares
            v-model="buyDialog"
            :bid.sync="currentBid"
            :selected="selected"
            @bid-close="handleBuyClose"
        />

        <vtx-bids
            v-model="bidDialog"
            :currentAuction="currentAuction"
            @bid-close2="handleBidClose"
        />
    </v-container>
</template>

<script>
import { mapState } from "vuex";

import moment from "moment";
import VtxBuyShares from "@/components/comp-buyshare.vue";
import VtxBids from "@/components/comp-vtx-bids.vue";
import TimeCounter from "@/components/TimeCounter";
import VtxProjectDetailsHeadline from "@/components/bits/bits-vtx-projectdetailsheadline.vue";

import { isMobile } from "@/common/helpers";

import { lineColorHelper, toCurrencyHelper } from "@/common/helpers";

export default {
    name: "view-projects",
    components: {
        TimeCounter,
        VtxBuyShares,
        VtxBids,
        VtxProjectDetailsHeadline,
    },
    data() {
        return {
            buyDialog: false,
            bidDialog: false,
            currentBid: {
                currency: "USD",
                count: 100,
                totalPercentFees: 2,
                totalForFractions: 0,
            },
            loc: "en-gb",
            fullscreen: false,
            currentAuction: null,
            imgHeight: null,
        };
    },

    computed: {
        ...mapState("auctions", ["selected"]),
        ...mapState("participants", ["current"]),
        ...mapState("wallets", ["wallets"]),

        canBid() {
            return (
                this.$auth.isAuthenticated &&
                this.current.kyc_status === "KYC_SUCCEEDED"
            );
        },

        isMobileScreen() {
            return isMobile(this);
        },
        getFdate() {
            return (v) => {
                let date = new Date(v);
                return date.toLocaleDateString(this.loc);
            };
        },

        toCurrency() {
            return (v, c) => {
                return toCurrencyHelper(v, c);
            };
        },

        getAuthorDescription() {
            return (selected) => {
                // check if data loaded to "selected"

                if (this.$i18n.locale == "pl") {
                    return selected.authorDescription_pl !== undefined
                        ? selected.authorDescription_pl
                        : "";
                } else {
                    return selected.authorDescription_en !== undefined
                        ? selected.authorDescription_en
                        : "";
                }
            };
        },
    },

    created() {
        this.selected.picture = "";
    },

    mounted() {
        var locale = window.navigator.userLanguage || window.navigator.language;
        moment.locale(locale);
        this.loc = moment.localeData()._abbr;
        this.getCurrentAuction();
    },

    methods: {
        // updateImgHeight() {
        //     const cardTextHeight = this.$refs.cardText.clientHeight;
        //     const windowHeight = window.innerHeight;
        //     // console.log(`${Math.min(cardTextHeight, windowHeight)}px`);
        // },

        async getCurrentAuction() {
            this.currentAuction = this.$route.params.auctionId;
            await this.$store
                .dispatch("auctions/getSelected", this.currentAuction)
                .then(() => {
                    // console.log(this.selected);
                    this.currentBid.currency = this.selected.currency;
                    this.currentBid.totalPercentFees =
                        this.selected.totalPercentFees;
                    this.currentBid.totalForFractions = 0;
                    this.currentBid.minUnitPrice = this.selected.minUnitPrice;
                });
        },
        lineColor(status) {
            return lineColorHelper(status);
        },

        toggleFullScreen() {
            this.fullscreen = !this.fullscreen;
        },

        handleBuyClose() {
            this.buyDialog = false;

            if (
                this.wallets[this.currentBid.currency] <
                this.currentBid.totalForFractions +
                    this.currentBid.totalForFractions *
                        (this.currentBid.totalPercentFees / 100)
            ) {
                this.$store.dispatch("snackbar/show", {
                    text: this.$t("not_enough_funds"),
                    color: "error",
                });
                return;
            }

            const bidToSend = {
                auctionId: this.currentAuction,
                currency: this.currentBid.currency,
                count: this.currentBid.count,
                totalPercentFees: this.currentBid.totalPercentFees,
                totalForFractions: parseFloat(
                    this.currentBid.totalForFractions
                ),
                maxFractionPrice: parseFloat(this.currentBid.maxFractionPrice),
            };

            this.$store.dispatch("auctions/placeBid", bidToSend);
        },
        handleBidClose() {
            this.bidsDialog = false;
        },
    },
};
</script>
