import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import router from "./router";
import store from "./store";
import VueFilterDateFormat from "vue-filter-date-format";
import VueFullscreen from "vue-fullscreen";
import CountryFlag from "vue-country-flag";
import interceptorsSetup from "@/plugins/interceptors";
import socketioPlugin from "@/plugins/socketio";

Vue.use(socketioPlugin);

import "./plugins/vuetify-money";

Vue.use(socketioPlugin, { store });

// auth0
import { Auth0Plugin } from "@/auth";

Vue.use(VueFilterDateFormat);
Vue.use(VueFullscreen);

Vue.component("country-flag", CountryFlag);

Vue.use(Auth0Plugin, {
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    redirectUri: process.env.VUE_APP_AUTH0_CALLBACK_URL,
    audience: "https://api.fractions.space",
    onRedirectCallback: (appState) => {
        router.push(
            appState && appState.targetPath
                ? appState.targetPath
                : window.location.pathname
        );
    },
});

Vue.config.productionTip = false;

import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

interceptorsSetup();

new Vue({
    vuetify,
    store,
    router,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
