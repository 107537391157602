<template>
    <v-app id="inspire">
        <v-navigation-drawer app v-model="drawer" v-if="isMobile">
            <v-list nav dense>
                <v-list-item link to="/projects">
                    <v-list-item-action>
                        <v-icon>mdi-rhombus-split</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{
                        $t("our_projects")
                    }}</v-list-item-title>
                </v-list-item>
                <v-list-item link to="/collection">
                    <v-list-item-action>
                        <v-icon>mdi-folder-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{
                        $t("your_collection")
                    }}</v-list-item-title>
                </v-list-item>

                <v-list-item @click="infoDialog = true">
                    <v-list-item-action>
                        <v-icon>mdi-shopping-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>Marketpalce</v-list-item-title>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list nav dense>
                <v-list-item to="/orders">
                    <v-list-item-action>
                        <v-icon>mdi-cart-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $t("orders") }}</v-list-item-title>
                </v-list-item>
                <v-list-item link to="profile">
                    <v-list-item-action>
                        <v-icon>mdi-account-circle-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{
                        $t("your_profile")
                    }}</v-list-item-title>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list nav dense>
                <v-list-item
                    @click="handleLogout()"
                    v-if="$auth.isAuthenticated"
                >
                    <v-list-item-action>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                    @click="handleLogin()"
                    v-if="!$auth.isAuthenticated"
                >
                    <v-list-item-action>
                        <v-icon>mdi-login</v-icon>
                    </v-list-item-action>
                    <v-list-item-title
                        >{{ $t("login") }} /
                        {{ $t("sign_up") }}</v-list-item-title
                    >
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar
            app
            color="white"
            flat
            dense
            clipped-left
            height="80"
            absolute
            ><v-container class="ma-auto" style="max-width: 1230px">
                <v-app-bar dense elevation="0" color="white">
                    <v-toolbar-title class="ml-0">
                        <router-link to="/">
                            <v-img
                                src="@/assets/arternity_logo_min.png"
                                max-height="55"
                                contain
                                position="center left"
                            />
                        </router-link>
                    </v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-title
                        v-if="!isMobile && !$auth.loading"
                        class="mr-5 text-align:right"
                    >
                        <v-btn
                            class="px-2"
                            elevation="0"
                            plain
                            link
                            to="/projects"
                        >
                            <span>{{ $t("our_projects") }}</span>
                        </v-btn>
                        <v-btn
                            class="pr-2"
                            elevation="0"
                            plain
                            @click="infoDialog = true"
                        >
                            <span>{{ $t("marketplace") }}</span>
                        </v-btn>
                        <v-btn
                            class="pr-2"
                            elevation="0"
                            plain
                            link
                            to="/collection"
                        >
                            <span>{{ $t("your_collection") }}</span>
                        </v-btn>
                    </v-toolbar-title>

                    <vtx-notifications v-if="$auth.isAuthenticated" />
                    <vtx-wallet v-if="$auth.isAuthenticated" />
                    <ProfileButton :profileVisible.sync="profileVisible" />
                    <LoginButton v-if="!isMobile" />

                    <v-btn icon @click="toggleLanguage" v-if="!isMobile">
                        <v-icon>mdi-translate</v-icon>
                    </v-btn>
                    <v-app-bar-nav-icon
                        @click="drawer = !drawer"
                        v-if="isMobile"
                    ></v-app-bar-nav-icon>
                </v-app-bar>
            </v-container>
        </v-app-bar>

        <v-main v-if="!isMainRoute" class="grey lighten-4">
            <VtxBitsTopAlert
                :message="information_bar"
                v-if="$auth.isAuthenticated && information_bar.message"
            />

            <!-- <comp-loading :loading="loading" color="deep-purple accent-4" /> -->
            <v-container fluid class="mt-0 pt-5">
                <v-sheet
                    class="overflow-y-auto mx-auto"
                    min-height="50vh"
                    rounded="lg"
                    elevation="0"
                    color="white"
                    style="max-width: 1223px"
                >
                    <router-view></router-view>
                    <vtx-bits-footer />
                </v-sheet>
            </v-container>
        </v-main>
        <v-main
            v-if="isMainRoute"
            style="background: linear-gradient(125deg, #fff 15%, #c3c3c3)"
        >
            <!-- <comp-loading :loading="loading" color="deep-purple accent-4" /> -->
            <v-container fluid class="mt-0 pa-0">
                <v-sheet
                    class="overflow-y-auto mx-auto rounded-0"
                    min-height="50vh"
                    elevation="0"
                    color="transparent"
                >
                    <router-view></router-view>
                </v-sheet>
            </v-container>
        </v-main>
        <CSnack />
        <VtxMangoTerms v-model="mangoTermsDialog" />
        <VtxBitsMarketPlaceInfo v-model="infoDialog" />
        <VtxCokiesConsent />
    </v-app>
</template>

<script>
import LoginButton from "@/components/comp-loginbutton.vue";
import ProfileButton from "@/components/comp-profilebutton.vue";
import CSnack from "@/components/comp-snack.vue";
// import CompLoading from "@/components/comp-loading.vue";
import VtxNotifications from "@/components/comp-notifications.vue";
import VtxWallet from "@/components/comp-vtx-wallet.vue";
import VtxMangoTerms from "@/components/comp-vtx-mango-terms.vue";
import VtxBitsFooter from "@/components/bits/bits-vtx-footer.vue";
import VtxBitsTopAlert from "@/components/bits/bits-vtx-topalert.vue";
import VtxBitsMarketPlaceInfo from "@/components/bits/bits-vtx-marketplaceinfo.vue";
import VtxCokiesConsent from "@/components/comp-vtx-cookiesconsent.vue";

import { mapState } from "vuex";

export default {
    title: "Arternity app",
    name: "App",
    components: {
        LoginButton,
        ProfileButton,
        CSnack,
        // CompLoading,
        VtxNotifications,
        VtxWallet,
        VtxMangoTerms,
        VtxBitsFooter,
        VtxBitsTopAlert,
        VtxBitsMarketPlaceInfo,
        VtxCokiesConsent,
    },

    created() {
        // watch the params of the route to fetch the data again

        this.$watch(
            () => this.$route.params,
            (toParams, previousParams) => {
                if (toParams !== previousParams) this.run();
            },
            // fetch the data when the view is created and the data is
            // already being observed
            { immediate: true }
        );

        this.$socket.$subscribe("message", function (payload) {
            console.log(payload);
        });
    },

    data() {
        return {
            drawer: false,
            infoDialog: false,
            mangoTermsDialog: false,
            mini: false,
            profileVisible: false,
        };
    },

    computed: {
        ...mapState("settings", ["loading"]),
        ...mapState("participants", ["current"]),

        isMainRoute() {
            return this.$route.name === "Main";
        },
        isMobile() {
            return (
                this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.sm
            );
        },
        isMobile2() {
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                )
            ) {
                return true;
            } else {
                return false;
            }
        },

        information_bar() {
            let tc = this.current;

            if (tc.email_verified === false) {
                return {
                    message: this.$t("email_not_verified"),
                    isButton: true,
                    buttonText: this.$t("resend"),
                    buttonCallback: null,
                };
            } else {
                if (!tc.kyc_status) {
                    return {
                        message: this.$t("kyc_not_verified"),
                        isButton: true,
                        buttonText: this.$t("your_profile"),
                        buttonCallback: this.goToProfile,
                    };
                } else if (
                    tc.kyc_status === "KYC_VALIDATION_ASKED" ||
                    tc.kyc_status === "VALIDATION_ASKED"
                ) {
                    return {
                        message: this.$t("kyc_pending"),
                        isButton: false,
                        buttonText: "",
                        buttonCallback: null,
                    };
                } else if (tc.kyc_status === "KYC_FAILED") {
                    return {
                        message: this.$t("kyc_failed"),
                        isButton: false,
                        buttonText: "",
                        buttonCallback: null,
                    };
                } else return ""; // all ok
            }
        },
    },

    methods: {
        async run() {
            await this.$store
                .dispatch("auth/retrieveTokenFromAuthz")
                .then(async () => {
                    this.initializeSocketConnection();
                    await this.$store
                        .dispatch("participants/getCurrent")
                        .then(() => {
                            this.mangoTermsDialog =
                                !this.$store.getters[
                                    "participants/mangotermsaccepted"
                                ];
                        });
                    // only for view-wallet initial load data
                    // neet to be here as it waits for auth token
                });
        },

        initializeSocketConnection() {
            const token = this.$store.getters["auth/token"];
            this.$socket.io.opts.query = { token };
        },

        async resendVerificationEmail() {
            await this.$store.dispatch("participants/resendVerificationEmail");
        },

        toggleLanguage() {
            if (this.$i18n.locale === "en") {
                this.$i18n.locale = "pl";
            } else {
                this.$i18n.locale = "en";
            }
        },
        goToProfile() {
            this.profileVisible = true;
        },

        handleLogin() {
            this.$auth.loginWithRedirect({
                authorizationParams: {
                    ui_locales: this.$i18n.locale || "en",
                },
            });
        },
        handleLogout() {
            this.$auth.logout({
                logoutParams: {
                    returnTo: process.env.VUE_APP_AUTH0_LOGOUT_URL,
                },
            });
        },
    },
};
</script>

<style>
html {
    overflow-y: auto;
}

#firstrow {
}
</style>
