<template>
    <span>
        {{ displayDays }} d {{ displayHours }}:{{ displayMin }}:{{ displaySec }}
        {{ $t("hours_short") }}
    </span>
</template>
<script>
export default {
    props: ["targetdate"],
    data: () => ({
        displayDays: 0,
        displayHours: 0,
        displayMin: 0,
        displaySec: 0,
    }),
    mounted() {
        this.showRemaining();
    },
    computed: {
        _sec: () => 1000,
        _min() {
            return this._sec * 60;
        },
        _hours() {
            return this._min * 60;
        },
        _days() {
            return this._hours * 24;
        },
        _end() {
            return new Date(this.targetdate);
        },
    },
    methods: {
        formatNum: (num) => (num < 10 ? "0" + num : num),
        showRemaining() {
            const timer = setInterval(() => {
                const now = new Date();

                const distance = this._end.getTime() - now.getTime();

                if (distance < 0) {
                    clearInterval(timer);
                    return;
                }

                const days = Math.floor(distance / this._days);

                const hours = Math.floor((distance % this._days) / this._hours);
                const min = Math.floor((distance % this._hours) / this._min);
                const sec = Math.floor((distance % this._min) / this._sec);

                this.displayMin = this.formatNum(min);

                this.displaySec = this.formatNum(sec);
                this.displayHours = this.formatNum(hours);
                this.displayDays = this.formatNum(days);
            }, 1000);
        },
    },
};
</script>
