const state = {
    loading: true,
    hasPersonalInfo: {},
    snackMessage: "",
    snackVisible: false,
    snackColor: "success",
};

const actions = {
    setLoading({ commit }, payload) {
        commit("SET_LOADING", payload);
    },
    setPersonalInfo({ commit }, payload) {
        commit("SET_PERSONAL_INFO", payload);
    },
    setSnack({ commit }, payload) {
        commit("SET_SNACK", payload);
    },
    setSnackVisible({ commit }, payload) {
        commit("SET_SNACK_VISIBLE", payload);
    },
    setError({ commit }, payload) {
        commit("SET_ERROR", payload);
    },
};

const mutations = {
    SET_LOADING: (state, val) => {
        state.loading = val;
    },
    SET_PERSONAL_INFO: (state, val) => {
        state.hasPersonalInfo = val;
    },
    SET_SNACK: (state, val) => {
        state.snackMessage = val;
        state.snackVisible = true;
    },
    SET_SNACK_VISIBLE: (state, val) => {
        state.snackVisible = val;
    },
    SET_ERROR: (state, val) => {
        state.snackMessage = val;
        console.log("error", val);
        switch (val) {
            case "ERROR_FILE_TOO_SMALL":
                state.snackMessage = "Provided scan is too small";
                break;
            default:
                state.snackMessage = "Unknown error";
        }
        state.snackVisible = true;
        state.snackColor = "error";
    },

    // https://github.com/probil/vue-socket.io-extended
    SOCKET_MESSAGE(state, message) {
        state.snackVisible = true;
        state.snackMessage = message;
    },
};

export const getters = {
    snackVisible: (state) => {
        return state.snackVisible;
    },
};

export default {
    namespaced: true,
    name: "settings",
    state,
    getters,
    actions,
    mutations,
};
