<template>
    <div v-if="$auth.isAuthenticated">
        <v-menu offset-y open-on-hover rounded="lg">
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-account-circle-outline</v-icon>
                </v-btn>
            </template>

            <v-list min-width="300px">
                <v-list-item @click="handleProfile">
                    <v-list-item-icon>
                        <v-icon>mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title
                            >{{ $t("your_profile") }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="/orders">
                    <v-list-item-icon>
                        <v-icon>mdi-cart-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title
                            >{{ $t("orders") }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="handlePayout" :disabled="!kycOK">
                    <v-list-item-icon>
                        <v-icon>mdi-cash-refund</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title
                            >{{ $t("payout_bank_details") }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>
                <v-list-item @click="handleLogout">
                    <v-list-item-icon>
                        <v-icon>mdi-logout-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("logout")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>
        <VtxProfile v-model="mutableProfileVisible" />
        <VtxPayout v-model="payoutVisible" />
    </div>
</template>
<script>
import VtxProfile from "@/components/comp-vtx-profile.vue";
import VtxPayout from "@/components/comp-vtx-payout.vue";
import { mapState } from "vuex";

export default {
    name: "CProfileButton",
    components: {
        VtxProfile,
        VtxPayout,
    },
    data: () => ({
        payoutVisible: false,
    }),
    props: ["profileVisible"],
    computed: {
        ...mapState("participants", ["current"]),
        kycOK() {
            return this.current.kyc_status === "KYC_SUCCEEDED";
        },

        mutableProfileVisible: {
            get() {
                return this.profileVisible;
            },
            set(value) {
                this.$emit("update:profileVisible", value);
            },
        },
    },
    methods: {
        handleLogout() {
            this.$auth.logout({
                logoutParams: {
                    returnTo: process.env.VUE_APP_AUTH0_LOGOUT_URL,
                },
            });
        },

        handleProfile() {
            this.mutableProfileVisible = true;
        },
        handlePayout() {
            this.payoutVisible = true;
        },
    },
};
</script>
