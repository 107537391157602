<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card elevation="0">
                    <v-card-text>
                        <v-row fluid>
                            <v-col>
                                <h2 style="text-transform: capitalize">
                                    {{ $t("your_collection") }}
                                </h2>
                            </v-col>
                            <v-col>
                                <v-btn
                                    class="float-right"
                                    outlined
                                    small
                                    @click="titled = !titled"
                                    :disabled="storageCount == 0"
                                >
                                    <v-icon>mdi-apps </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider class="mx-4"></v-divider>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="!titled">
            <v-col>
                <v-data-table
                    :headers="headers"
                    :items="mystorage"
                    class="elevation-0 ma-3"
                    :footer-props="footerProps"
                >
                    <template v-slot:[`item.picture`]="{ item }">
                        <v-img
                            :src="item.picture"
                            :alt="item.title"
                            max-height="100"
                            max-width="100"
                            :aspect-ratio="4 / 3"
                            class="mt-2 mb-2"
                        ></v-img>
                    </template>
                    <template v-slot:[`item.nftassetaddr`]="{ item }">
                        <vtx-bits-algobutton :item="item.nftassetaddr" />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row v-if="titled">
            <v-col
                lg="6"
                xl="6"
                md="6"
                sm="6"
                xs="12"
                cols="12"
                v-for="item in mystorage"
                :key="item.id"
            >
                <v-card
                    class="mx-3 my-3 event-card"
                    rounded="lg"
                    :to="{ path: `/details/${item.projectId}` }"
                >
                    <v-img
                        height="250"
                        :aspect-ratio="4 / 3"
                        :src="item.picture"
                    ></v-img>
                    <v-card-title>
                        <div>
                            <div class="text-h5">{{ item.title }}</div>
                            <div class="text-h6">{{ item.authorName }}</div>
                        </div>
                    </v-card-title>

                    <v-card-text>
                        <div class="text-subtitle-1">
                            {{ $t("franctions_owned") }}
                        </div>
                        <div class="text-h5">
                            <span style="color: #517ef4">
                                {{ item.amount }}
                            </span>
                        </div>
                        <div class="text-overline">
                            ~{{
                                calcOwnedVsTotalQuantity(
                                    item.amount,
                                    item.quantity
                                )
                            }}% {{ $t("total_supply") }}
                        </div>
                    </v-card-text>

                    <v-divider class="mx-4"></v-divider>

                    <v-card-actions>
                        <v-btn
                            color="deep-purple lighten-2"
                            text
                            @click="intent"
                        >
                            {{ $t("details") }}
                        </v-btn>
                        <v-spacer />
                        <vtx-bits-algobutton :item="item.nftassetaddr" />
                    </v-card-actions>
                    <div class="date-ribbon">
                        <h3>IN VAULT</h3>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import VtxBitsAlgobutton from "@/components/bits/bits-vtx-algobutton.vue";

export default {
    name: "view-projects",
    components: { VtxBitsAlgobutton },
    data() {
        return {
            loc: "en-gb",
            titled: false,
        };
    },

    computed: {
        ...mapState("storage", ["mystorage"]),

        toCurrency() {
            return (v, c) => {
                if (typeof v !== "number") {
                    return v;
                }
                var formatter = new Intl.NumberFormat(this.loc, {
                    style: "currency",
                    currency: c,
                });
                return formatter.format(v);
            };
        },

        headers() {
            return [
                {
                    text: "",
                    align: "left",
                    value: "picture",
                    width: "5%",
                },
                {
                    text: this.$t("title"),
                    align: "left",
                    value: "title",
                    width: "15%",
                },
                {
                    text: this.$t("author"),
                    align: "left",
                    value: "authorName",
                    width: "10%",
                },
                {
                    text: this.$t("total_supply"),
                    align: "left",
                    value: "amount",
                    width: "10%",
                },
                {
                    text: this.$t("your_tokens"),
                    align: "left",
                    value: "amount",
                    width: "10%",
                },
                {
                    text: this.$t("asset_addr"),
                    align: "left",
                    value: "nftassetaddr",
                    width: "10%",
                },
            ];
        },

        footerProps() {
            let props = {
                "items-per-page-text": this.$t("rows_per_page"),
                "items-per-page-options": [5, 10],
            };
            return props;
        },

        storageCount() {
            return this.mystorage.length;
        },
    },

    created() {
        this.$watch(
            () => this.$store.state.auth.token,
            () => {
                this.getCollection();
            }
        );
    },

    mounted() {
        var locale = window.navigator.userLanguage || window.navigator.language;
        moment.locale(locale);
        this.loc = moment.localeData()._abbr;

        this.getCollection();
    },

    methods: {
        getCollection() {
            let st = this.$store;
            st.dispatch("settings/setLoading", true, { root: true });
            st.dispatch("storage/loadItems").then(() => {
                st.dispatch("settings/setLoading", false, { root: true });
            });
        },
        calcOwnedVsTotalQuantity(count, quantity) {
            return Math.round((count * 100) / quantity);
        },
    },
};
</script>

<style>
.headerClass {
    white-space: nowrap;
    word-break: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.event-card .date-ribbon {
    position: absolute;
    top: 0;
    right: 2em;
    background: #aa00ff;
    color: #fff;
    padding: 0.2em 1em;
    padding-bottom: 0.2em;
    border-radius: 3;
}

.event-card .date-ribbon h3 {
    font-weight: 500;
    font-size: 0.8em;
    letter-spacing: 0.07em;
    text-align: center;
}
</style>
