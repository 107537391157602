<template>
    <v-toolbar elevation="0" class="px-1" :color="color" dark dense>
        <v-toolbar-title>
            {{ title }}
        </v-toolbar-title>

        <v-spacer />

        <v-btn icon class="mr" @click="$emit('close')" elevation="0">
            <v-icon>mdi-close</v-icon>
        </v-btn>
    </v-toolbar>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "",
        },
        color: {
            type: String,
            default: "pink",
        },
    },
};
</script>
