<template>
    <v-dialog
        v-model="collectDialog"
        width="auto"
        max-width="500"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        transition="dialog-bottom-transition"
        rounded="lg"
        persistent
    >
        <v-card rounded="lg">
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-card-title class="text-h5 my-2 grey lighten-4">
                    {{ $t("wallet_top_up") }}
                </v-card-title>

                <v-card-text>
                    <v-radio-group
                        row
                        v-model="cardType"
                        class="mx-5 mt-5 mb-0 justify-center"
                    >
                        <v-radio
                            label="Option 1"
                            value="CB_VISA_MASTERCARD"
                            class="mx-auto"
                        >
                            <template v-slot:label>
                                <v-img
                                    width="60px"
                                    class="mr-7"
                                    src="@/assets/visa_icon.png"
                                ></v-img>
                            </template>
                        </v-radio>
                        <v-radio
                            label="Option 2"
                            value="MAESTRO"
                            class="mx-auto"
                        >
                            <template v-slot:label>
                                <v-img
                                    width="60px"
                                    class="mr-7"
                                    src="@/assets/maestro_icon.png"
                                ></v-img>
                            </template>
                        </v-radio>
                        <v-radio label="Option 3" value="P24" class="mx-auto">
                            <template v-slot:label>
                                <v-img
                                    width="70px"
                                    src="@/assets/przelewy24_icon.png"
                                ></v-img>
                            </template>
                        </v-radio>
                    </v-radio-group>

                    <v-card class="mx-5 mt-0 pa-5" elevation="1">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-select
                                    v-model="currency"
                                    :items="currencies"
                                    label="Currency"
                                    required
                                    readonly
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <vuetify-money
                                    v-model="amount"
                                    class="text-h4"
                                    :label="$t('total_to_checkout')"
                                    :options="moneyOptions"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-checkbox v-model="agreed" dense>
                                    <template v-slot:label>
                                        <span style="font-size: 0.9rem">{{
                                            $t("agree_with_terms")
                                        }}</span>
                                    </template>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-btn
                                    block
                                    :disabled="!agreed"
                                    color="#517ef4"
                                    @click="checkout"
                                >
                                    <span style="color: white">
                                        {{ $t("go_to_checkout") }}
                                    </span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-card-text>

                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn text @click="collectDialog = false">
                        {{ $t("cancel") }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from "moment";
import { currencies, transactionStatusHelper } from "@/common/helpers";

export default {
    props: ["value", "walletData"],
    data() {
        return {
            valid: true,
            agreed: false,
            amount: 0,
            cardType: "CB_VISA_MASTERCARD",
            currency: "USD",
            currencies: currencies,
        };
    },

    beforeMount() {
        var locale = window.navigator.userLanguage || window.navigator.language;
        moment.locale(locale);
        this.loc = moment.localeData()._abbr;
    },

    watch: {
        collectDialog(state) {
            if (state === true) {
                this.currency = this.walletData.Balance.Currency;
            }
            if (state === false) {
                this.$refs.form.resetValidation();
                this.amount = 0;
            }
        },
    },
    methods: {
        async checkout() {
            let payload = {
                amount: this.amount,
                currency: this.currency,
                cardType: this.cardType,
                walletId: this.walletData.Id,
                returnURL: window.location.href,
            };

            await this.$store
                .dispatch("wallets/payIn", payload)
                .then((res) => {
                    if (res.status == "CREATED") {
                        if (res.secureModeRedirectURL) {
                            window.location.href = res.secureModeRedirectURL;
                        } else {
                            window.location.href = res.redirectURL;
                        }
                    }
                    if (res.status == "FAILED") {
                        this.collectDialog = false;
                        this.$store.dispatch(
                            "settings/setSnack",
                            transactionStatusHelper(res.mangoCode)
                        );
                    }
                })
                .catch((err) => {
                    this.collectDialog = false;
                    this.$store.dispatch(
                        "settings/setSnack",
                        transactionStatusHelper(err.type)
                    );
                });
        },
    },
    computed: {
        collectDialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        moneyOptions() {
            return {
                prefix: this.currency,
                suffix: "",
                precision: 2,
                masked: true,
                length: 11,
                locale: this.loc,
            };
        },
    },
};
</script>
