<template>
    <v-snackbar v-model="snackVisible" timeout="5000" top color="snackColor">
        {{ snackMessage }}
        <template v-slot:action="{ attrs }">
            <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="snackVisible = false"
            >
                {{ $t("close") }}
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "CSnack",
    computed: {
        ...mapState("settings", ["snackMessage", "snackColor"]),
        snackVisible: {
            get() {
                return this.$store.getters["settings/snackVisible"];
            },
            set(value) {
                return this.$store.dispatch("settings/setSnackVisible", value);
            },
        },
    },
};
</script>
