import Vue from "vue";
import VueRouter from "vue-router";
import Projects from "../views/view-projects.vue";
import Details from "../views/view-project-details.vue";
import Collection from "../views/view-collection.vue";
import Main from "../views/view-main.vue";
import ViewWallet from "../views/view-wallet.vue";
import Orders from "../views/view-orders.vue";
import Messages from "../views/view-messages.vue";

import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter);

const routes = [
    {
        path: "/projects",
        name: "Projects",
        component: Projects,
    },
    {
        path: "/details/:auctionId",
        name: "Details",
        component: Details,
    },
    {
        path: "/collection",
        name: "Collection",
        beforeEnter: authGuard,
        component: Collection,
    },
    {
        path: "/viewwallet/:walletCurrency",
        name: "ViewWallet",
        component: ViewWallet,
        beforeEnter: authGuard,
    },
    {
        path: "/orders",
        name: "Orders",
        component: Orders,
        beforeEnter: authGuard,
    },
    {
        path: "/messages",
        name: "Messages",
        component: Messages,
        beforeEnter: authGuard,
    },
    {
        path: "/callback",
        redirect: "/projects",
    },
    {
        // ? - allows for optional parameter, also read this https://auth0.com/docs/authenticate/login/logout
        path: "/:logout?",
        // redirect: "/projects",
        name: "Main",
        component: Main,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
