<template>
    <v-select
        v-if="phone"
        v-model="txtInput"
        filled
        persistent-hint
        hide-details="auto"
        :items="country_list"
        item-text="phone"
        item-value="phone"
        :disabled="disabled"
        :label="label"
        :prepend-icon="icon"
        :rules="stringRulesToArray"
    >
        <template v-slot:item="{ item, on }">
            <v-list-item v-on="on">
                <v-list-item-action>
                    <country-flag
                        :country="item.countryShortCode"
                        size="small"
                    />
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        <v-row no-gutters>
                            {{ item.countryName }} + {{ item.phone }}
                        </v-row>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-select>
    <v-select
        v-else
        v-model="txtInput"
        filled
        persistent-hint
        hide-details="auto"
        :items="country_list"
        :disabled="disabled"
        item-text="countryName"
        item-value="countryShortCode"
        :label="label"
        :prepend-icon="icon"
        :rules="stringRulesToArray"
    >
        <template v-slot:item="{ item, on }">
            <v-list-item v-on="on">
                <v-list-item-action>
                    <country-flag
                        :country="item.countryShortCode"
                        size="small"
                    />
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        <v-row no-gutters>
                            {{ item.countryName }}
                        </v-row>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-select>
</template>

<script>
import { COUNTRY_PL } from "@/data/country_pl";
import { COUNTRY_EN } from "@/data/country_en";

export default {
    name: "vtx-phone",
    props: ["label", "value", "disabled", "icon", "phone", "rules"],
    data() {
        return {
            rulesdef: {
                required: (value) => !!value || this.$t("required"),
            },
        };
    },
    computed: {
        stringRulesToArray() {
            if (this.rules) {
                let all_rules = this.rules.split("|").map((element) => {
                    return element.trim();
                });
                let arr_rules = [];
                if (all_rules.includes("required")) {
                    arr_rules.push(this.rulesdef.required);
                }
                return arr_rules;
            }
            return [];
        },
        txtInput: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        country_list() {
            return this.$i18n.locale === "en" ? COUNTRY_EN : COUNTRY_PL;
        },
    },
};
</script>
