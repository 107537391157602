var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.bidDialog),callback:function ($$v) {_vm.bidDialog=$$v},expression:"bidDialog"}},[_c('v-card',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',{staticClass:"text-h5 grey lighten-4"},[_vm._v(" "+_vm._s(_vm.$t("reserve_a_slot"))+" ... ")]),_c('v-divider'),_c('v-card-title',{staticClass:"text-subtitle my-0 font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t("and_i_will_invest"))+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('vuetify-money',{attrs:{"label":_vm.$t('total_for_shares'),"options":_vm.moneyOptions,"properties":{
                                'persistent-placeholder': true,
                            },"rules":[
                                _vm.rules.gt0(
                                    this.vTotal,
                                    _vm.$t('value_not_zero')
                                ),
                            ]},model:{value:(_vm.mutableBid.totalForFractions),callback:function ($$v) {_vm.$set(_vm.mutableBid, "totalForFractions", $$v)},expression:"mutableBid.totalForFractions"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6"}},[(_vm.bidDialog)?_c('vtx-tokenprice',{attrs:{"auctionId":_vm.selected.id},model:{value:(_vm.mutableBid.maxFractionPrice),callback:function ($$v) {_vm.$set(_vm.mutableBid, "maxFractionPrice", $$v)},expression:"mutableBid.maxFractionPrice"}}):_vm._e(),_c('vuetify-money',{attrs:{"label":_vm.$t('max_price_per_fraction'),"options":_vm.moneyOptions2,"properties":{
                                'persistent-placeholder': true,
                            },"rules":[
                                _vm.rules.lteqmin(
                                    this.vTokenMax,
                                    this.vTokenMin,
                                    _vm.$t('must_be_more_equal')
                                ),
                                _vm.rules.gt0(
                                    this.vTokenMax,
                                    _vm.$t('value_not_zero')
                                ),
                                _vm.rules.ltmax(
                                    this.vTokenMax,
                                    this.vTotal,
                                    _vm.$t('must_be_less_total')
                                ),
                            ]},model:{value:(_vm.mutableBid.maxFractionPrice),callback:function ($$v) {_vm.$set(_vm.mutableBid, "maxFractionPrice", $$v)},expression:"mutableBid.maxFractionPrice"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-divider')],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('vuetify-money',{attrs:{"value":_vm.fee,"label":_vm.$t('transaction_fees'),"options":_vm.moneyOptions2,"properties":{
                                'persistent-placeholder': true,
                            },"read-only":"","disabled":""}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('vuetify-money',{attrs:{"value":_vm.total,"label":_vm.$t('total_to_checkout'),"options":_vm.moneyOptions2,"properties":{
                                'persistent-placeholder': true,
                            },"read-only":"","disabled":""}})],1)],1)],1),_c('v-col',[_c('v-divider')],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.bidDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","disabled":!_vm.transValid},on:{"click":_vm.bidProcess}},[_vm._v(" "+_vm._s(_vm.$t("go_to_checkout"))+" ")])],1)],1)],1),_c('vtx-bidconfirmation',{ref:"confirmationDialog",attrs:{"bid":_vm.mutableBid,"selected":_vm.selected,"walletBalance":_vm.getWalletBalance()}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }