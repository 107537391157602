<template>
    <v-bottom-sheet v-model="showConsentSheet" max-width="1200" hide-overlay>
        <template v-slot:activator="{ on }">
            <v-btn
                v-if="!isConsentGiven"
                fab
                bottom
                right
                dark
                small
                color="primary"
                class="ma-4"
                v-on="on"
            >
                <v-icon>mdi-cookie</v-icon>
            </v-btn>
        </template>
        <v-container fluid class="pa-0">
            <v-card class="mx-auto" outlined>
                <v-card-title>
                    <span class="headline">{{
                        $t("cookie_consent_headline")
                    }}</span>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" class="py-2">
                            {{ $t("cookie_consent") }}
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="rejectCookies"
                        color="deep-purple"
                        rounded
                        >Reject</v-btn
                    >
                    <v-btn
                        @click="acceptCookies"
                        color="deep-purple white--text"
                        rounded
                        elevation="0"
                        >Accept</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-container>
    </v-bottom-sheet>
</template>

<script>
export default {
    data() {
        return {
            showConsentSheet: false,
        };
    },
    computed: {
        isConsentGiven() {
            const consent = localStorage.getItem("cookieConsent");
            return consent === "accepted" || consent === "rejected";
        },
    },
    methods: {
        acceptCookies() {
            localStorage.setItem("cookieConsent", "accepted");
            this.showConsentSheet = false;
        },
        rejectCookies() {
            localStorage.setItem("cookieConsent", "rejected");
            this.showConsentSheet = false;
        },
    },
    mounted() {
        if (!this.isConsentGiven) {
            // Show the consent sheet on the first page view
            this.showConsentSheet = true;
        }
    },
};
</script>
