import Vue from "vue";
import Vuex from "vuex";
import AuctionsModule from "./auctions.module";

import AuthModule from "./auth.module";
import ParticipantsModule from "./participants.module";
import StorageModule from "./storage.module";
import SettingsModule from "./settings.module";
import WalletsModule from "./wallets.module";
import OrdersModule from "./orders.module";
import MessagesModule from "./messages.module";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        auctions: AuctionsModule,
        participants: ParticipantsModule,
        auth: AuthModule,
        storage: StorageModule,
        settings: SettingsModule,
        wallets: WalletsModule,
        orders: OrdersModule,
        messages: MessagesModule,
    },
});

export default store;
