import { getInstance } from "@/auth";

const state = {
    token: null,
};

const getters = {
    token(state) {
        return state.token;
    },
};

const mutations = {
    SET_TOKEN(state, token) {
        state.token = token;
    },
};

const actions = {
    retrieveTokenFromAuthz(context) {
        return new Promise((resolve, reject) => {
            const instance = getInstance();
            //console.log("instance", instance);
            instance.$watch("loading", (loading) => {
                if (loading === false && instance.isAuthenticated) {
                    instance
                        .getTokenSilently()
                        .then((authToken) => {
                            context.commit("SET_TOKEN", authToken);
                            resolve(authToken);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                }
            });
        });
    },
};

export default {
    namespaced: true,
    name: "auth",
    state,
    actions,
    mutations,
    getters,
};
