<template>
    <v-menu
        nudge-left="60"
        nudge-bottom="57"
        transition="slide-y-transition"
        float-left
        elevation="0"
        rounded="lg"
    >
        <template #activator="{ on: onbtn, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="onbtn"
                :disabled="wallets.length === 0"
            >
                <v-icon>mdi-wallet-outline</v-icon>
            </v-btn>
        </template>

        <v-card max-width="400" @click.stop="">
            <v-card-title>
                {{ $t("your_balance") }}
                <v-spacer></v-spacer>
            </v-card-title>
            <v-slide-group
                v-model="walletslide"
                style="height: 140px"
                show-arrows=""
            >
                <v-slide-item
                    v-for="n in wallets.length"
                    :key="n"
                    disabled
                    class="ml-4"
                >
                    <v-card
                        color="primary"
                        class="mr-4"
                        height="100"
                        width="240"
                        @click="showDetails(wallets[n - 1].Balance.Currency)"
                    >
                        <v-card-text class="text-h6"
                            ><span style="color: white">
                                {{ wallets[n - 1].Balance.Currency }}
                                <v-spacer />
                                {{
                                    toCurrencyCents(
                                        wallets[n - 1].Balance.Amount,
                                        wallets[n - 1].Balance.Currency
                                    )
                                }}</span
                            ></v-card-text
                        >
                        <v-btn
                            fab
                            elevation="2"
                            absolute
                            bottom
                            right
                            small
                            color="#d200df"
                            @click="payIn(wallets[n - 1])"
                        >
                            <v-icon color="white"> mdi-plus </v-icon>
                        </v-btn>
                    </v-card>
                </v-slide-item>
            </v-slide-group>
            <v-spacer></v-spacer>
        </v-card>
        <vtx-collect v-model="collectDialog" :walletData="currentWallet" />
    </v-menu>
</template>

<script>
import { mapState } from "vuex";
import { toCurrencyHelper } from "@/common/helpers";
import VtxCollect from "@/components/comp-vtx-collect.vue";

export default {
    components: {
        VtxCollect,
    },
    data() {
        return {
            walletslide: 0,
            currentWallet: {},
            collectDialog: false,
        };
    },

    methods: {
        payIn(wallet) {
            this.currentWallet = wallet;
            this.collectDialog = true;
        },
        showDetails(currency) {
            this.$router
                .push("/viewwallet/" + currency.toUpperCase())
                .catch(() => {});
        },
        payOut(wallet) {
            this.this.$store.dispatch("wallets/payOut", {
                amount: "100",
                currency: wallet.Balance.Currency,
                walletId: wallet.Id,
            });
        },
    },
    computed: {
        ...mapState("settings", ["hasPersonalInfo"]),
        ...mapState("wallets", ["wallets"]),

        toCurrencyCents() {
            return (cents, currency) => {
                let money = cents / 100;
                return toCurrencyHelper(money, currency);
            };
        },
    },
};
</script>

<style></style>
