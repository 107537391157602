<template>
    <v-container fluid class="mb-10">
        <v-row>
            <v-col>
                <v-card elevation="0">
                    <v-card-text>
                        <h2 style="text-transform: capitalize">
                            {{ $t("active_projects") }}
                        </h2>
                    </v-card-text>
                    <v-divider class="mx-4"></v-divider>
                </v-card>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col
                lg="4"
                xl="4"
                md="4"
                sm="6"
                xs="12"
                cols="12"
                v-for="item in activated"
                :key="item.id"
                class="my-4"
            >
                <v-hover v-slot="{ hover }" close-delay="100">
                    <v-card
                        class="mx-3 d-flex flex-column"
                        color="#FAFAFA"
                        :to="{ path: `/details/${item.id}` }"
                        height="100%"
                        :elevation="hover ? 2 : 1"
                    >
                        <v-img height="250" :src="item.picture"></v-img>
                        <v-card-title>
                            <div>
                                <div class="text-subtitle-1 font-weight-bold">
                                    {{ item.title
                                    }}<span v-if="item.year"
                                        >, {{ item.year }}</span
                                    >
                                </div>
                                <div class="text-subtitle-1">
                                    {{ item.author.name }}
                                </div>
                            </div>
                        </v-card-title>

                        <v-card-text>
                            <div class="text-subtitle-1">
                                {{ $t("min_price") }}
                            </div>
                            <div class="text-h5 mb-2">
                                <span style="color: #517ef4">
                                    {{
                                        toCurrency(item.minPrice, item.currency)
                                    }}
                                </span>
                            </div>
                            <div class="text-overline mt-1">
                                {{ progressText(item.status) }}
                            </div>

                            <v-progress-linear
                                height="25"
                                :value="item.reached"
                                dark
                                :color="lineColor(item.status)"
                                class="my-1 elevation-0"
                            >
                                <template v-slot:default="{ value }">
                                    <strong>{{ value }}%</strong>
                                </template>
                            </v-progress-linear>

                            <v-row
                                no-gutters
                                v-if="
                                    item.status === 'A' && item.reached === 100
                                "
                            >
                                <v-col>
                                    <v-tooltip
                                        top
                                        class="pa-0 ma-0"
                                        max-width="200"
                                        color="black"
                                    >
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                small
                                                v-bind="attrs"
                                                v-on="on"
                                                >mdi-information-outline
                                            </v-icon>

                                            <span class="text-overline">
                                                {{
                                                    $t("min_price_reached")
                                                }}</span
                                            >
                                        </template>
                                        <div class="ma-2">
                                            {{ $t("reached_info") }}
                                        </div>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                            <v-row no-gutters v-else>
                                <v-col>
                                    <br />
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-spacer></v-spacer>
                        <v-card-text class="text-subtitle-2">
                            <v-row no-gutters v-if="item.status == 'A'">
                                <v-col>
                                    <v-icon small class="mr-1"
                                        >mdi-clock-time-eight-outline</v-icon
                                    >{{ daysLeft(item.endsAt) }}
                                    {{ $t("until_the_end") }}
                                </v-col>
                            </v-row>
                            <v-row
                                no-gutters
                                v-if="auction_statuses.includes(item.status)"
                            >
                                <v-col>
                                    <v-icon small class="mr-1"
                                        >mdi-clock-time-eight-outline</v-icon
                                    >{{ daysLeft(item.auctionDateTime) }}
                                    {{ $t("days_to_auction") }}
                                </v-col>
                            </v-row>
                            <v-row
                                no-gutters
                                v-if="
                                    !auction_statuses.includes(item.status) &&
                                    !(item.status == 'A')
                                "
                            >
                                <v-col>
                                    <br />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card elevation="0">
                    <v-card-text>
                        <h2 style="text-transform: capitalize">
                            {{ $t("past_projects") }}
                        </h2>
                    </v-card-text>
                    <v-divider class="mx-4"></v-divider>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                lg="4"
                xl="4"
                md="4"
                sm="6"
                xs="12"
                v-for="item in previous"
                :key="item.id"
            >
                <v-card class="mx-3 ml-3" rounded="lg">
                    <v-img
                        style="filter: grayscale(1)"
                        height="250"
                        :src="item.picture"
                    ></v-img>
                    <v-card-title class="text-subtitle-1 font-weight-bold">{{
                        item.title
                    }}</v-card-title>
                    <v-card-text>
                        <v-row align="center" class="mx-0">
                            <div class="grey--text">
                                {{ item.author.name }}
                            </div>
                        </v-row>

                        <div class="my-4 text-subtitle-1"></div>

                        <div></div>
                    </v-card-text>

                    <v-divider class="mx-4"></v-divider>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { lineColorHelper, toCurrencyHelper } from "@/common/helpers";

export default {
    name: "view-projects",
    data() {
        return {
            loc: "en-gb",
            auction_statuses: ["E", "Z", "O"],
            show_info: false,
        };
    },

    computed: {
        ...mapState("auctions", ["activated", "previous"]),

        toCurrency() {
            return (v, c) => {
                return toCurrencyHelper(v, c);
            };
        },
    },

    mounted() {
        var locale = window.navigator.userLanguage || window.navigator.language;
        moment.locale(locale);
        this.loc = moment.localeData()._abbr;

        this.$store.dispatch("auctions/loadItems");
        this.$store.dispatch("auctions/loadPrevItems");
    },

    methods: {
        lineColor(status) {
            return lineColorHelper(status);
        },

        daysLeft(date) {
            let days = moment(date).diff(moment(), "days");
            return days > 0 ? days : 0;
        },

        progressTextMinPrice(reached, status) {
            if (status === "A") {
                if (reached >= 100) {
                    return "Reached min. price";
                }
            }
            return "<br/>";
        },

        progressText(status) {
            if (status === "A") {
                return this.$t("fundraising_in_progresss");
            }
            if (status === "E") {
                return this.$t("waiting_for_settelement");
            }
            if (status === "Z") {
                return this.$t("cancelled_not_enough_funds");
            }
            if (status === "O") {
                return this.$t("waiting_for_auction_result");
            }
            if (status === "W") {
                return this.$t("auction_won");
            }
            if (status === "P") {
                return this.$t("auction_lost");
            }
            if (status === "F") {
                return this.$t("auction_archived");
            }
        },
    },
};
</script>

<style>
.headerClass {
    white-space: nowrap;
    word-break: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
