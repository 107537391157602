<template>
    <div>
        <v-container fluid>
            <v-row
                align="center"
                style="min-height: 80vh; max-width: 1223px"
                class="mx-auto"
            >
                <v-col
                    lg="6"
                    xl="6"
                    md="6"
                    sm="6"
                    xs="12"
                    cols="12"
                    style="height: 100%"
                >
                    <div>
                        <!-- <v-img
                            src="@/assets/arternity_logo.png"
                            max-width="300px"
                        /> -->

                        <div
                            class="mt-3 text-h4 text-xl-h2 text-lg-h2 text-md-h2 text-sm-h4 rotatingText font-weight-normal"
                        >
                            <span class="spin">{{ $t("discover") }}<br /></span>
                            <span class="spin">{{ $t("collect") }}<br /></span>
                            <span class="spin">{{ $t("sell") }}<br /></span>
                        </div>
                        <div
                            class="text-h4 text-xl-h2 text-lg-h2 text-md-h2 text-sm-h4 font-weight-normal"
                        >
                            {{ $t("main_discover") }}
                        </div>
                        <div
                            class="text-xl-h5 text-lg-h5 text-h6 font-weight-light font-weight-normal"
                        >
                            {{ $t("main_enable_fine_art") }}
                        </div>

                        <v-btn
                            dark
                            class="mt-5"
                            to="/projects"
                            elevation="0"
                            rounded
                        >
                            {{ $t("main_see_our_projects") }}
                        </v-btn>
                    </div>
                </v-col>
                <v-col
                    lg="6"
                    xl="6"
                    md="6"
                    sm="6"
                    xs="6"
                    cols="12"
                    style="height: 100%"
                >
                    <v-hover v-slot="{ hover }" close-delay="200">
                        <v-card
                            rounded="lg"
                            :elevation="hover ? 2 : 0"
                            style="padding: 20px"
                        >
                            <v-img
                                :aspect-ratio="4 / 3"
                                :src="featured.picture"
                                class="padding:5px;"
                            >
                            </v-img>

                            <v-card-subtitle v-if="featured.author">
                                {{ featured.title }}
                                <div class="grey--text">
                                    {{ featured.author.name }}
                                </div>
                            </v-card-subtitle>

                            <v-divider class="mx-4"></v-divider>

                            <v-card-actions>
                                <v-btn
                                    color="deep-purple lighten-2"
                                    text
                                    :to="{
                                        path: `/details/${featured.id}`,
                                    }"
                                >
                                    {{ $t("project_details") }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>
        </v-container>
        <v-container fluid style="background-color: white">
            <v-row
                style="max-width: 1223px"
                align="center"
                class="mx-auto pb-5"
            >
                <v-col lg="12" xl="12" md="12" sm="12" xs="12" cols="12">
                    <div style="margin-top: 4vh">
                        <div
                            class="text-h4 font-weight-bold"
                            style="color: #424242; text-align: center"
                        >
                            {{ $t("main_easy_to_use") }}
                        </div>
                    </div>
                </v-col>

                <v-col lg="4" xl="4" md="4" sm="12" xs="12" cols="12">
                    <v-card
                        height="100%"
                        color="#F1F1F1"
                        class="align-self-center elevation-0 mx-3 my-3"
                        rounded="lg"
                        style="min-height: 300px"
                    >
                        <v-card-title class="justify-center">
                            <v-icon x-large>mdi-finance</v-icon>
                        </v-card-title>
                        <v-card-text
                            class="text-center text-h6 font-weight-light"
                        >
                            {{ $t("main_argument_1") }}
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col lg="4" xl="4" md="4" sm="12" xs="12" cols="12">
                    <v-card
                        height="100%"
                        color="#F1F1F1"
                        class="align-self-center elevation-0 mx-3 my-3"
                        rounded="lg"
                        style="min-height: 300px"
                    >
                        <v-card-title class="justify-center">
                            <v-icon x-large>mdi-apps</v-icon>
                        </v-card-title>
                        <v-card-text
                            class="text-center text-h6 font-weight-light"
                        >
                            {{ $t("main_argument_2") }}
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col lg="4" xl="4" md="4" sm="12" xs="12" cols="12">
                    <v-card
                        height="100%"
                        color="#F1F1F1"
                        class="align-self-center elevation-0 mx-3 my-3"
                        rounded="lg"
                        style="min-height: 300px"
                    >
                        <v-card-title class="justify-center">
                            <v-icon x-large>mdi-link-lock</v-icon>
                        </v-card-title>
                        <v-card-text
                            class="text-center text-h6 font-weight-light"
                        >
                            {{ $t("main_argument_3") }}
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-container fluid>
            <v-row class="pt-5">
                <v-col lg="12" xl="12" md="12" sm="12" xs="12" cols="12">
                    <div
                        class="text-h4 font-weight-bold mx-3 text-center"
                        style="color: #424242"
                    >
                        {{ $t("featured_projects") }}
                    </div>
                </v-col>
            </v-row>

            <v-row
                class="mx-auto pb-5"
                style="max-width: 1223px"
                align="center"
            >
                <v-col
                    lg="4"
                    xl="4"
                    md="4"
                    sm="6"
                    xs="12"
                    v-for="item in activated"
                    :key="item.id"
                >
                    <v-card
                        class="mx-3 my-3"
                        rounded="lg"
                        :to="{ path: `/details/${item.id}` }"
                    >
                        <v-img height="250" :src="item.picture"></v-img>

                        <v-card-subtitle>
                            {{ item.title }}
                            <div class="grey--text">
                                {{ item.author.name }}
                            </div>
                        </v-card-subtitle>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-container fluid style="background-color: #894bef">
            <v-row
                style="max-width: 1223px"
                class="mx-auto pb-5"
                align="center"
            >
                <v-col class="pa-5">
                    <div
                        style="margin-top: 4vh"
                        class="text-h5 text-lg-h4 white--text font-weight-medium text-uppercase"
                    >
                        {{ $t("main_engage") }}
                    </div>
                    <v-btn
                        class="mt-5"
                        style="margin-bottom: 4vh"
                        @click="handleSignup"
                        elevation="0"
                        rounded
                    >
                        {{ $t("sign_up") }}</v-btn
                    >
                </v-col>
            </v-row>
        </v-container>
        <vtx-bits-footer />
    </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import VtxBitsFooter from "@/components/bits/bits-vtx-footer.vue";

export default {
    name: "view-projects",
    components: {
        VtxBitsFooter,
    },
    data() {
        return {
            loc: "en-gb",
            hover: false,
            options: {
                duration: 2000,
                offset: 0,
                easing: "easeInOutCubic",
            },
        };
    },

    created() {
        if (this.$route.params.logout === "logout") {
            this.$store.dispatch(
                "settings/setSnack",
                this.$t("you_have_been_logged_out")
            );
        }
    },

    computed: {
        ...mapState("auctions", ["activated", "featured"]),
    },

    mounted() {
        var locale = window.navigator.userLanguage || window.navigator.language;
        moment.locale(locale);
        this.loc = moment.localeData()._abbr;
        this.$store.dispatch("auctions/loadFeatured");
        this.$store.dispatch("auctions/loadItems");
        this.$store.dispatch("auctions/loadPrevItems");
    },
    methods: {
        handleSignup() {
            this.$auth.loginWithRedirect({
                authorizationParams: {
                    ui_locales: this.$i18n.locale || "en",
                    screen_hint: "signup",
                },
            });
        },

        scrollDown() {
            this.$vuetify.goTo(this.$refs.section_two, this.options);
        },
    },
};
</script>

<style>
.headerClass {
    white-space: nowrap;
    word-break: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

#firstrow {
    background: linear-gradient(0deg, #ffffff, rgba(0, 0, 0, 0.09));
}

.rotatingText span:nth-of-type(1) {
    animation: rotate-text-up 12s infinite;
}

.rotatingText span:nth-of-type(2) {
    animation: rotate-text-up 12s 4s infinite;
}

.rotatingText span:nth-of-type(3) {
    animation: rotate-text-up 12s 8s infinite;
}

.spin {
    text-transform: uppercase;
    position: absolute;
    display: block;
    opacity: 0;
}

@media (max-width: 600px) {
    .spin {
        font-size: 2.125rem !important;
    }
    .rotatingText {
        height: 2.5rem !important;
    }
}

@media (min-width: 600px) {
    .spin {
        font-size: 2.125rem !important;
    }
    .rotatingText {
        height: 2.5rem !important;
    }
}
@media (min-width: 960px) {
    .spin {
        font-size: 3.75rem !important;
    }
    .rotatingText {
        height: 3.75rem !important;
    }
}

@media (min-width: 1264px) {
    .spin {
        font-size: 3.75rem !important;
    }
    .rotatingText {
        height: 4rem !important;
    }
}

@keyframes rotate-text-up {
    0% {
        transform: translate3d(0, 80px, 0);
        opacity: 0;
    }

    5%,
    20% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    40% {
        transform: translate3d(0, -40px, 0);
        opacity: 0;
    }
}
</style>
