<template>
    <div>
        <div class="text-overline pink--text" v-if="price">
            {{ $t("last_token_price") }}: {{ price }} {{ currency }}
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isLoading: false,
            price: null,
            currency: null,
            visibility: true,
        };
    },
    props: {
        auctionId: {
            type: String,
            required: true,
        },
    },
    methods: {
        async fetchPrice() {
            if (!this.visibility) {
                return; // Don't fetch when component is not visible
            }
            this.isLoading = true;
            await this.$store
                .dispatch("auctions/getTokenPrice", this.auctionId)
                .then((fetchResult) => {
                    this.price = fetchResult.currentMinSharePrice;
                    this.currency = fetchResult.currency;
                    //this.isLoading = false;
                })
                .catch((error) => {
                    console.error(error);
                    // this.isLoading = false;
                });
        },
    },
    mounted() {
        this.fetchPrice();
        this.interval = setInterval(() => {
            this.fetchPrice();
        }, 10000);
    },
    beforeDestroy() {
        // Clear the interval when the component is about to be destroyed

        clearInterval(this.interval);
    },
};
</script>
