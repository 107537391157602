// socketPlugin.js
import Vue from "vue";
import VueSocketIOExt from "vue-socket.io-extended";
import io from "socket.io-client";
import store from "../store";

const socket = io(process.env.VUE_APP_SOCKET_URL, {
    transports: ["websocket"],
});

// Use VueSocketio with the socket instance
Vue.use(VueSocketIOExt, socket, { store });

export default {
    install(Vue) {
        Vue.prototype.$socket = socket;
    },
};
