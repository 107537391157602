import { sendApi } from "../common/helpers";

const state = {
    mystorage: [],
};

const mutations = {
    SET_STORAGE: (state, val) => {
        state.mystorage = val;
    },
};

const actions = {
    async loadItems({ commit }) {
        const url = `/storage/`;
        await sendApi(url, "get").then((response) => {
            commit("SET_STORAGE", response.data);
        });
    },
};

export default {
    namespaced: true,
    name: "storage",
    state,
    actions,
    mutations,
};
