import { sendApi } from "../common/helpers";

const state = {
    messages: [],
};

const mutations = {
    SET_MESSAGES: (state, val) => {
        state.messages = val;
    },
};

const actions = {
    async getMessages({ commit }) {
        let url = "/messages";
        await sendApi(url, "get").then((response) => {
            commit("SET_MESSAGES", response.data);
        });
    },
};

export default {
    namespaced: true,
    name: "messages",
    state,
    actions,
    mutations,
};
