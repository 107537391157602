<template>
    <div class="text-center">
        <v-dialog
            v-model="bidsDialog"
            :fullscreen="isMobile"
            max-width="900"
            rounded="lg-2"
            scrollable
            persistent
        >
            <v-card rounded="lg" elevation="0">
                <v-toolbar
                    elevation="0"
                    class="px-2"
                    color="#d200df"
                    dark
                    max-height="4rem"
                >
                    <v-toolbar-title>
                        {{ $t("reservations_for_project") }}
                    </v-toolbar-title>
                    <v-spacer />

                    <v-btn
                        icon
                        class="mr"
                        @click="bidsDialog = false"
                        elevation="0"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="ma-0 pa-0">
                    <v-data-table
                        :headers="headers"
                        :items="bids"
                        item-key="bidId"
                        style="min-height: 200px"
                        max-height="100vh"
                        class="elevation-1"
                    >
                        <template v-slot:[`item.status`]="{ item }">
                            <vtx-status-chip
                                :status="item.status"
                            ></vtx-status-chip>
                        </template>
                        <template v-slot:[`item.dateSend`]="{ item }">
                            {{ getFdate(item.dateSend) }}
                        </template>
                        <template v-slot:[`item.givenFractions`]="{ item }">
                            <v-chip v-if="item.givenFractions == 0">
                                {{ item.givenFractions }}
                            </v-chip>
                            <v-chip v-else color="#517EF4">
                                <span style="color: #fff">{{
                                    item.givenFractions
                                }}</span>
                            </v-chip>
                        </template>
                        <template v-slot:[`item.totalForFractions`]="{ item }">
                            {{
                                toCurrency(
                                    item.totalForFractions,
                                    item.currency
                                )
                            }}
                        </template>
                        <template v-slot:[`item.maxFractionPrice`]="{ item }">
                            {{
                                toCurrency(item.maxFractionPrice, item.currency)
                            }}
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-divider></v-divider>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { toCurrencyHelper } from "@/common/helpers";
import VtxStatusChip from "@/components/bits/bits-vtx-status-chip.vue";

export default {
    components: { VtxStatusChip },
    props: ["value", "currentAuction"],
    data() {
        return {
            loc: "en-gb",
            histShow: false,
        };
    },

    mounted() {
        var locale = window.navigator.userLanguage || window.navigator.language;
        moment.locale(locale);
        this.loc = moment.localeData()._abbr;
    },

    watch: {
        bidsDialog: function (val) {
            if (val) {
                this.$store.dispatch(
                    "auctions/getBitsForAuction",
                    this.currentAuction
                );
            }
        },
    },

    computed: {
        ...mapState("auctions", ["bids"]),

        isMobile() {
            return (
                this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.sm
            );
        },
        bidsDialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        getFdate() {
            return (v) => {
                let date = new Date(v);
                return date.toLocaleString(this.loc);
            };
        },
        toCurrency() {
            return (v, c) => {
                return toCurrencyHelper(v, c);
            };
        },
        headers() {
            return [
                {
                    text: this.$t("date"),
                    align: "start",
                    sortable: true,
                    value: "dateSend",
                    width: "180px",
                },
                {
                    text: "Status",
                    align: "start",
                    sortable: false,
                    value: "status",
                    width: "20px",
                },
                {
                    text: this.$t("total"),
                    align: "right",
                    sortable: true,
                    value: "totalForFractions",
                    width: "120px",
                },
                {
                    text: this.$t("price_per_share"),
                    align: "right",
                    sortable: true,
                    value: "maxFractionPrice",
                    width: "160px",
                },

                {
                    text: this.$t("Client"),
                    align: "start",
                    sortable: false,
                    value: "userP",
                },
                {
                    text: this.$t("fractions_count_short"),
                    align: "right",
                    sortable: true,
                    value: "givenFractions",
                    width: "120px",
                },
            ];
        },
    },

    methods: {
        closeDialog() {
            this.alert = false;
            this.bidsDialog = false;
        },

        async cancel() {
            this.closeDialog();
        },
    },
};
</script>

<style scoped>
.stepper {
    height: 100%;
    overflow: visible;
}
</style>
