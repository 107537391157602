<template>
    <v-btn
        color="deep-purple lighten-2"
        class="white--text"
        text
        @click="redirect(item)"
    >
        <v-icon>mdi-transit-connection-horizontal</v-icon>&nbsp;
        <v-img
            :aspect-ratio="4 / 3"
            src="@/assets/algorand_purple.png"
            contain
            style="filter: opacity(0.8)"
            max-height="32px"
            max-width="32px"
            class="text-right"
        ></v-img>
        {{ item }}
    </v-btn>
</template>
<script>
import { redirectToAssetExplorer } from "@/common/helpers";
export default {
    props: {
        item: {
            type: String,
            required: true,
        },
    },
    methods: {
        redirect(addr) {
            redirectToAssetExplorer(addr);
        },
    },
};
</script>
