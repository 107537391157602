<template>
    <div v-if="!$auth.loading">
        <v-tooltip bottom v-if="!$auth.isAuthenticated">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    class="px-5 mr-3"
                    @click="handleSignup"
                    v-bind="attrs"
                    v-on="on"
                    elevation="0"
                    outlined
                    rounded
                >
                    <span>{{ $t("sign_up") }}</span>
                </v-btn>
            </template>
            <span>{{ $t("sign_up") }}</span>
        </v-tooltip>

        <v-tooltip bottom v-if="!$auth.isAuthenticated">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    class="px-5 mr-3"
                    @click="handleLogin"
                    v-bind="attrs"
                    v-on="on"
                    elevation="0"
                    rounded
                    dark
                >
                    <span style="color: white">{{ $t("login") }}</span>
                </v-btn>
            </template>
            <span>{{ $t("login") }}</span>
        </v-tooltip>
    </div>
</template>
<script>
export default {
    name: "CLoginButton",
    methods: {
        handleLogin() {
            this.$auth.loginWithRedirect({
                authorizationParams: {
                    ui_locales: this.$i18n.locale || "en",
                },
            });
        },
        handleSignup() {
            this.$auth.loginWithRedirect({
                authorizationParams: {
                    ui_locales: this.$i18n.locale || "en",
                    screen_hint: "signup",
                },
            });
        },
    },
};
</script>
