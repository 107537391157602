<template>
    <v-dialog v-model="bidDialog" max-width="500">
        <v-card>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-card-title class="text-h5 grey lighten-4">
                    {{ $t("reserve_a_slot") }} ...
                </v-card-title>
                <v-divider></v-divider>
                <v-card-title class="text-subtitle my-0 font-weight-light">
                    {{ $t("and_i_will_invest") }}
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6" sm="6">
                            <vuetify-money
                                v-model="mutableBid.totalForFractions"
                                :label="$t('total_for_shares')"
                                :options="moneyOptions"
                                :properties="{
                                    'persistent-placeholder': true,
                                }"
                                :rules="[
                                    rules.gt0(
                                        this.vTotal,
                                        $t('value_not_zero')
                                    ),
                                ]"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6">
                            <!-- v-if is really important here to clear inveral
                            of reading price -->
                            <vtx-tokenprice
                                v-if="bidDialog"
                                :auctionId="selected.id"
                                v-model="mutableBid.maxFractionPrice"
                            >
                            </vtx-tokenprice>
                            <vuetify-money
                                v-model="mutableBid.maxFractionPrice"
                                :label="$t('max_price_per_fraction')"
                                :options="moneyOptions2"
                                :properties="{
                                    'persistent-placeholder': true,
                                }"
                                :rules="[
                                    rules.lteqmin(
                                        this.vTokenMax,
                                        this.vTokenMin,
                                        $t('must_be_more_equal')
                                    ),
                                    rules.gt0(
                                        this.vTokenMax,
                                        $t('value_not_zero')
                                    ),
                                    rules.ltmax(
                                        this.vTokenMax,
                                        this.vTotal,
                                        $t('must_be_less_total')
                                    ),
                                ]"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-divider></v-divider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" sm="6">
                            <vuetify-money
                                :value="fee"
                                :label="$t('transaction_fees')"
                                :options="moneyOptions2"
                                :properties="{
                                    'persistent-placeholder': true,
                                }"
                                read-only
                                disabled
                            />
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" sm="6">
                            <vuetify-money
                                :value="total"
                                :label="$t('total_to_checkout')"
                                :options="moneyOptions2"
                                :properties="{
                                    'persistent-placeholder': true,
                                }"
                                read-only
                                disabled
                            />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-col>
                    <v-divider></v-divider>
                </v-col>
                <v-card-actions>
                    <v-btn text @click="bidDialog = false">
                        {{ $t("cancel") }}
                    </v-btn>

                    <v-btn text @click="bidProcess" :disabled="!transValid">
                        {{ $t("go_to_checkout") }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
        <vtx-bidconfirmation
            :bid="mutableBid"
            :selected="selected"
            :walletBalance="getWalletBalance()"
            ref="confirmationDialog"
        ></vtx-bidconfirmation>
    </v-dialog>
</template>
<script>
import moment from "moment";
import VtxBidconfirmation from "@/components/bits/bits-vtx-bidconfirmation.vue";
import VtxTokenprice from "@/components/bits/bits-vtx-tokenprice.vue";
import currency from "currency.js";
import { mapState } from "vuex";

export default {
    components: {
        VtxBidconfirmation,
        VtxTokenprice,
    },
    props: ["value", "bid", "selected"],
    data() {
        return {
            valid: true,
            loc: "en-gb",
            locale: "en-gb",
        };
    },

    created() {
        this.locale =
            window.navigator.userLanguage || window.navigator.language;
        moment.locale(this.locale);
        this.loc = moment.localeData()._abbr;
    },

    watch: {
        bidDialog(visible) {
            if (visible) {
                this.mutableBid.agreed = false;
                if (this.$refs.form) this.$refs.form.resetValidation();
            }
        },
    },

    methods: {
        async bidProcess() {
            const confirmed = await this.$refs.confirmationDialog.open();
            if (confirmed) {
                this.$emit("bid-close");
                this.mutableBid.totalForFractions = 0;
            }
        },
        confirm() {
            this.$refs.cDialog.result = true;
            this.confirmDialog = false;
        },
        cancel() {
            this.$refs.cDialog.result = false;
            this.dialogVisible = false;
        },

        getWalletBalance() {
            let wallet = this.wallets.find(
                (w) => w.Currency === this.mutableBid.currency
            );
            console.log(wallet);
            return wallet.Balance.Amount;
        },
    },
    computed: {
        ...mapState("wallets", ["wallets"]),
        transValid() {
            return (
                this.vTotal > 0 &&
                this.vTokenMax > 0 &&
                this.vTokenMax >= this.vTokenMin &&
                this.vTokenMax <= this.vTotal
            );
        },
        moneyOptions() {
            return {
                prefix: this.mutableBid.currency,
                suffix: "",
                precision: 0,
                masked: true,
                length: 11,
                min: 1,
                locale: this.locale,
            };
        },
        moneyOptions2() {
            return {
                prefix: this.mutableBid.currency,
                suffix: "",
                precision: 2,
                masked: true,
                length: 11,
                min: 1,
                locale: this.locale,
            };
        },

        vTokenMin() {
            return Number(this.mutableBid.minUnitPrice);
        },

        vTokensFees() {
            return Number(this.bid.totalPercentFees);
        },

        vTokenMax() {
            return Number(this.mutableBid.maxFractionPrice);
        },

        vTotal() {
            return Number(this.mutableBid.totalForFractions);
        },

        fee() {
            let fees = this.vTokensFees / 100;
            return currency(this.vTotal).multiply(fees).value;
        },

        total() {
            return this.vTotal + this.fee;
        },

        rules() {
            return {
                ltmax: (value, max, msg) => {
                    return parseInt(value) < max || msg + " " + max;
                },
                lteqmin: (value, min, msg) => {
                    return parseInt(value) >= parseInt(min) || msg + " " + min;
                },
                gt0: (value, msg) => {
                    return parseInt(value) > 0 || msg;
                },
            };
        },

        bidDialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },

        mutableBid: {
            get() {
                return this.bid;
            },
            set(value) {
                let rValue = value;
                rValue.totalForFractions = this.vTotal;
                rValue.maxFractionPrice = this.vTokenMax;
                this.$emit("update:bid", rValue);
            },
        },
    },
};
</script>
