<template>
    <div style="background: #8e24aa">
        <v-container class="mx-auto mt-0 mb-0 pa-0" style="max-width: 1230px">
            <v-alert
                dense
                color="#8E24AA"
                elevation="0"
                rounded="0"
                class="text-subtitle-2 font-weight-medium text-center pa-2"
            >
                <span style="color: white"> {{ message.message }} </span>
                <v-btn
                    elevation="elavation-0"
                    rounded="0"
                    class="mx-2"
                    dense
                    x-small
                    v-if="message.isButton"
                    @click="message.buttonCallback"
                >
                    {{ message.buttonText }}
                </v-btn>
            </v-alert>
        </v-container>
    </div>
</template>

<script>
export default {
    props: {
        message: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    methods: {
        handleButtonClick() {
            // Execute the callback function passed as a prop
            this.message.buttonCallback();
        },
    },
};
</script>
