<template>
    <v-container
        fluid
        style="background-color: #172510"
        class="py-10 rounded-0"
    >
        <v-row style="max-width: 1223px" class="mx-auto" align="center">
            <v-col
                lg="3"
                xl="3"
                md="3"
                sm="4"
                xs="12"
                class="align-self-start rounded-0"
            >
                <div class="subtitle white--text font-weight-medium">
                    Arternity
                </div>
                <p
                    class="subtitle white--text font-weight-light"
                    style="line-height: 200%"
                >
                    {{ $t("about_us") }}<br />
                    <a target="_blank" href="https://algorand.com/">
                        Algorand blockchain </a
                    ><br />
                    <a target="_blank" href="https://mangopay.com/">
                        Mangopay </a
                    ><br />
                    {{ $t("contact_us") }}<br />
                </p>
            </v-col>
            <v-col lg="3" xl="3" md="3" sm="4" xs="12" class="align-self-start">
                <div class="subtitle white--text font-weight-medium">Legal</div>
                <p
                    class="subtitle white--text font-weight-light"
                    style="line-height: 200%"
                >
                    <a
                        href="https://mangopay.com/terms/payment-services_EN_2023.pdf"
                        target="_blank"
                    >
                        {{ $t("terms_and_conditions_arternity") }}</a
                    ><br />

                    <a
                        href="https://mangopay.com/terms/payment-services_EN_2023.pdf"
                        target="_blank"
                    >
                        {{ $t("terms_and_conditions_mango") }}</a
                    ><br />

                    {{ $t("privacy_policy") }}<br />
                </p>
            </v-col>
            <v-col lg="3" xl="3" md="3" sm="4" xs="12" class="align-self-start">
                <div class="subtitle white--text font-weight-medium">FAQ</div>
                <p
                    class="subtitle white--text font-weight-light"
                    style="line-height: 200%"
                >
                    {{ $t("faq_how_does_it_work") }}<br />
                    {{ $t("faq_why_kyc") }}<br />
                    {{ $t("faq_physical_artwork_secure") }}<br />
                </p>
            </v-col>
            <v-col lg="3" xl="3" md="3" sm="4" xs="12" class="align-self-start">
                <v-spacer></v-spacer>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {};
</script>
<style scoped>
.subtitle {
    font-size: 1rem;
    color: #ffffff;
}

.subtitle a {
    color: #ffffff;
    text-decoration: none;
}

.subtitle a:hover {
    color: #ffffff;
    text-decoration: underline;
}

.subtitle a:visited {
    color: #ffffff;
    text-decoration: none;
}
</style>
```
