<template>
    <v-container fluid>
        <v-row v-if="!titled">
            <v-col>
                <v-data-table
                    :headers="headers"
                    :items="transactions"
                    class="elevation-0 ma-3"
                    :footer-props="footerProps"
                    :options.sync="options"
                    :server-items-length="totalItems"
                    height="500px"
                    :no-data-text="no_data_text"
                    rounded
                >
                    <template v-slot:[`item.CreationDate`]="{ item }">
                        {{ formatDateTime(item.CreationDate) }}
                    </template>

                    <template v-slot:[`item.DebitedFunds`]="{ item }">
                        {{
                            toCurrency(
                                item.DebitedFunds.Amount,
                                item.DebitedFunds.Currency
                            )
                        }}
                    </template>
                    <template v-slot:[`item.CreditedFunds`]="{ item }">
                        {{
                            toCurrency(
                                item.CreditedFunds.Amount,
                                item.CreditedFunds.Currency
                            )
                        }}
                    </template>
                    <template v-slot:[`item.Fees`]="{ item }">
                        {{ toCurrency(item.Fees.Amount, item.Fees.Currency) }}
                    </template>

                    <template v-slot:[`item.Status`]="{ item }">
                        <v-chip
                            v-if="item.Status == 'SUCCEEDED'"
                            color="pink"
                            small
                        >
                            <span style="color: #fff">{{ item.Status }}</span>
                        </v-chip>
                        <v-chip v-else color="grey" small>
                            <span style="color: #fff">{{ item.Status }}</span>
                        </v-chip>
                    </template>

                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title style="text-transform: capitalize">
                                {{ $t("wallet_transactions") }}
                            </v-toolbar-title>
                            <v-divider class="ml-2" inset vertical></v-divider>
                            <v-select
                                class="ml-3 mt-6 col-sm-3 col-4 col-md-2 col-lg-2 currency-select"
                                v-model="currency"
                                :items="currencies"
                                dense
                                outlined
                                item-text="name"
                                item-value="code"
                                @change="handleCurrencyChange"
                            />

                            <v-spacer></v-spacer>
                            <v-btn
                                small
                                class="elevation-0"
                                @click="showPayoutDialog"
                            >
                                {{ currency }} {{ $t("payout") }}
                            </v-btn>
                        </v-toolbar>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <vtx-payout-amount
            v-model="payoutDialog"
            :editedItem.sync="payoutRecord"
            @close-payout="handlePayout"
        />
    </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
import { currencies } from "@/common/helpers";
import { toCurrencyHelper, transactionStatusHelper } from "@/common/helpers";
import VtxPayoutAmount from "@/components/comp-vtx-payout-amount.vue";

export default {
    name: "view-wallet",
    components: {
        VtxPayoutAmount,
    },
    data() {
        return {
            payoutDialog: false,
            payoutRecord: {},
            loc: "en-gb",
            titled: false,
            currencies: currencies,
            currency: "EUR",
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            pagination: {},

            totalItems: 0,
            options: {
                page: 1,
                itemsPerPage: 10,
            },
        };
    },

    async mounted() {
        // moment.locale(this.$i18n.locale);
        this.options.page = 1;
        this.currency = this.$route.params.walletCurrency;
    },

    watch: {
        options: {
            handler: function () {
                this.getTransactions();
            },
            deep: true,
            immediate: true,
        },
        "$route.params.walletCurrency": {
            handler: function (cur) {
                this.options.page = 1;
                this.currency = cur;
                this.getTransactions();
            },
            deep: true,
            immediate: true,
        },
    },

    computed: {
        ...mapState("wallets", [
            "transactions",
            "transactionsCount",
            "transactionsPages",
        ]),

        ...mapState("participants", ["current"]),
        ...mapState("participants", ["bankAccount"]),

        headers() {
            return [
                {
                    text: this.$t("Date"),
                    align: "left",
                    value: "CreationDate",
                    width: "15%",
                    sortable: true,
                },
                {
                    text: this.$t("amount_debited"),
                    align: "right",
                    value: "DebitedFunds",
                    width: "10%",
                    sortable: false,
                },
                {
                    text: this.$t("amount_credited"),
                    align: "right",
                    value: "CreditedFunds",
                    width: "10%",
                    sortable: false,
                },
                {
                    text: this.$t("Fees"),
                    align: "right",
                    value: "Fees",
                    width: "10%",
                    sortable: false,
                },
                {
                    text: this.$t("Type"),
                    align: "left",
                    value: "Type",
                    width: "10%",
                    sortable: true,
                },
                {
                    text: this.$t("Status"),
                    align: "left",
                    value: "Status",
                    width: "10%",
                    sortable: true,
                },
                {
                    text: this.$t("Add_info"),
                    align: "left",
                    value: "ResultMessage",

                    sortable: true,
                },
                {
                    text: "",
                    align: "left",
                },
            ];
        },

        footerProps() {
            let props = {
                "items-per-page-text": this.$t("rows_per_page"),
                "items-per-page-options": [5, 10],
            };
            return props;
        },

        toCurrency() {
            return (v, c) => {
                return toCurrencyHelper(v / 100, c);
            };
        },

        no_data_text() {
            return this.$t("no_data_text");
        },
    },

    methods: {
        ...mapActions({
            setLoading: "settings/setLoading",
        }),

        async showPayoutDialog() {
            await this.$store
                .dispatch("participants/getCurrentBankAccount")
                .then(() => {
                    this.payoutRecord = Object.assign(
                        {},
                        {
                            amount: 0,
                            currency: this.currency,
                            bankBic: this.bankAccount.bank_bic,
                            bankIban: this.bankAccount.bank_iban,
                        }
                    );
                    this.payoutDialog = true;
                });
        },
        handlePayout() {
            this.$store
                .dispatch("wallets/payOut", {
                    amount: this.payoutRecord.amount,
                    currency: this.payoutRecord.currency,
                    walletId: this.current.walletId,
                })
                .then(() => {
                    this.$store.dispatch(
                        "settings/setSnack",
                        "Payout request sent"
                    );
                    this.getTransactions();
                });
            this.payoutDialog = false;
        },
        handleCurrencyChange() {
            this.$router
                .replace({
                    name: "ViewWallet",
                    params: { walletCurrency: this.currency },
                })
                .catch(() => {});
        },

        async getTransactions() {
            this.setLoading(true);

            let transactionId = this.$route.query.transactionId;
            if (transactionId != undefined) {
                this.$store
                    .dispatch("wallets/getTransactionStatus", transactionId)
                    .then((result) => {
                        // console.log(result);
                        // do not show further slack
                        // let query = this.$route.query;
                        this.$router.replace({ query: null }).catch(() => {});

                        this.$store.dispatch(
                            "settings/setSnack",
                            transactionStatusHelper(result)
                        );
                    });
            }

            await this.$store
                .dispatch("wallets/getTransactions", {
                    currency: this.currency,
                    page: this.options.page,
                })
                .then(() => {
                    this.totalItems = this.transactionsCount;
                    this.pageCount = this.transactionsPages;

                    this.setLoading(false);
                })
                .catch(() => {
                    this.setLoading(false);
                });
        },
        formatDateTime(unixtime) {
            return (
                moment.unix(unixtime).format("L") +
                " " +
                moment.unix(unixtime).format("LT")
            );
        },
    },
};
</script>

<style scoped>
.currency-select {
    font-size: smaller;
}
.theme--light.v-data-table {
    background-color: #fafafa;
    border-radius: 10px;
}
</style>
