<template>
    <v-text-field
        v-model="txtInput"
        filled
        hide-details="auto"
        persistent-hint
        :prepend-icon="icon"
        :label="label"
        :disabled="disabled"
        :rules="getRules"
        @input="handleUserInput"
    ></v-text-field>
</template>

<script>
export default {
    name: "vtx-form",
    props: ["label", "value", "disabled", "icon", "rules"],

    data() {
        return {
            rulesList: {},
        };
    },

    created() {},

    methods: {
        handleUserInput() {
            var x = this.txtInput
                .replace(/\D/g, "")
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.txtInput = !x[2]
                ? x[1]
                : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
        },
    },

    computed: {
        txtInput: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },

        mutableTxtInput: {
            get() {
                return this.txtInput;
            },
        },

        getRules() {
            if (this.rules === "required") {
                return [(v) => !!v || this.$t("required")];
            } else if (this.rules === "nameRules5") {
                return [
                    (v) => !!v || this.$t("required"),
                    (v) =>
                        (v && v.length >= 5) ||
                        "... " +
                            this.$t("must_be_more_than") +
                            " 5 " +
                            this.$t("characters"),
                ];
            } else if (this.rules === "nameRules2") {
                return [
                    (v) => !!v || this.$t("required"),
                    (v) =>
                        (v && v.length >= 2) ||
                        "... " +
                            this.$t("must_be_more_than") +
                            " 2 " +
                            this.$t("characters"),
                ];
            } else return [];
        },
    },
};
</script>
