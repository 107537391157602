import { sendApi } from "../common/helpers";

const state = {
    activated: [],
    bids: [],
    featured: {},
    previous: [],
    selected: {},
};

const getters = {};

const actions = {
    async placeBid(_, payload) {
        const url = `/bids`;
        await sendApi(url, "post", payload)
            .then(() => {
                // commit("SET_FUND", result.data.Item);
            })
            .catch((error) => {
                console.log(error);
            });
    },
    async getSelected({ commit }, item) {
        const url = `/auctions/details/${item}`;
        await sendApi(url, "get").then((result) => {
            commit("SET_SELECTED", result.data);
        });
    },
    async loadItems({ commit, dispatch }) {
        dispatch("settings/setLoading", true, { root: true });
        const url = `/auctions/activated`;
        await sendApi(url, "get")
            .then((response) => response.data)
            .then((items) => {
                commit("SET_ACTIVATED", items);
                dispatch("settings/setLoading", false, { root: true });
            });
    },
    async loadFeatured({ commit }) {
        const url = `/auctions/featured`;
        await sendApi(url, "get").then((items) => {
            console.log(items);
            commit("SET_FEATURED", items.data);
        });
    },
    async loadPrevItems({ commit }) {
        const url = `/auctions/previous`;
        await sendApi(url, "get")
            .then((response) => response.data)
            .then((items) => {
                commit("SET_PREV", items);
            });
    },
    async getBitsForAuction({ commit }, item) {
        const url = `/bids/${item}`;
        await sendApi(url, "get").then((items) => {
            commit("SET_BIDS", items.data);
        });
    },
    async getTokenPrice(_, item) {
        return new Promise((resolve, reject) => {
            const url = `/auctions/tokenprice/${item}`;
            sendApi(url, "get")
                .then((items) => {
                    resolve(items.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

const mutations = {
    SET_ACTIVATED: (state, val) => {
        state.activated = val;
    },
    SET_PREV: (state, val) => {
        state.previous = val;
    },
    SET_FUND: (state, val) => {
        state.selected.funds.push(val);
    },
    SET_SELECTED: (state, item) => {
        state.selected = item;
    },
    SET_FEATURED: (state, item) => {
        state.featured = item;
    },
    SET_BIDS: (state, item) => {
        state.bids = item;
    },
};

export default {
    namespaced: true,
    name: "auctions",
    state,
    getters,
    actions,
    mutations,
};
