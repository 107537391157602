<template>
    <v-tooltip left max-width="300">
        <template v-slot:activator="{ on, attrs }">
            <v-chip
                v-bind="attrs"
                v-on="on"
                class="ma-2 white--text"
                small
                v-if="getFullStatus(status).color == 'green_status'"
                color="#517EF4"
            >
                {{ getFullStatus(status).status }}
                <v-icon color="white" small class="ml-1"
                    >mdi-information-outline</v-icon
                >
            </v-chip>
            <v-chip
                v-bind="attrs"
                v-on="on"
                class="ma-2"
                small
                v-if="getFullStatus(status).color == 'red_status'"
            >
                {{ getFullStatus(status).status }}
                <v-icon color="pink" small class="ml-1"
                    >mdi-alert-circle</v-icon
                >
            </v-chip>
        </template>
        <span>{{ getFullStatus(status).fullDescription }}</span>
    </v-tooltip>
</template>

<script>
import { fullStatus } from "@/common/helpers";
export default {
    props: {
        status: {
            type: String,
            required: true,
        },
    },
    methods: {
        getFullStatus(sts) {
            return fullStatus(sts);
        },
    },
};
</script>
