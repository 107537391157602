<template>
    <v-menu
        :close-on-content-click="true"
        nudge-left="60"
        nudge-bottom="57"
        transition="slide-y-transition"
        float-left
        elevation="0"
        v-model="isMenuOpen"
        rounded="lg"
    >
        <template #activator="{ on: onbtn, attrs }">
            <v-badge
                v-if="hasNotifications"
                color="purple lighten-4"
                overlap
                dot
                offset-y="23px"
                offset-x="17px"
            >
                <v-btn icon v-bind="attrs" v-on="onbtn">
                    <v-icon>mdi-bell-outline</v-icon>
                </v-btn>
            </v-badge>
        </template>

        <v-card>
            <v-spacer></v-spacer>
            <v-list two-line>
                <v-list-item-group v-model="selected">
                    <template v-for="(item, index) in messages">
                        <v-list-item :key="item.title">
                            <template v-slot:default="{ active }">
                                <v-list-item-content>
                                    <v-list-item-title
                                        v-text="item.topic"
                                    ></v-list-item-title>

                                    <v-list-item-subtitle
                                        v-text="item.message"
                                    ></v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-list-item-action-text>{{
                                        getFdate(item.messageDT)
                                    }}</v-list-item-action-text>
                                    <v-icon
                                        v-if="!active"
                                        color="pink lighten-1"
                                    >
                                    </v-icon>

                                    <v-icon color="yellow darken-3">
                                        mdi-exclamation-thick
                                    </v-icon>
                                </v-list-item-action>
                            </template>
                        </v-list-item>

                        <v-divider
                            v-if="index < messages.length - 1"
                            :key="index"
                        ></v-divider>
                    </template>
                </v-list-item-group>
            </v-list>
            <v-spacer></v-spacer>

            <v-card-actions>
                <v-btn text href="/messages">{{
                    $t("see_all_messages")
                }}</v-btn>
            </v-card-actions>

            <v-divider></v-divider>
        </v-card>
    </v-menu>
</template>

<script>
import { mapState } from "vuex";
export default {
    data() {
        return {
            isMenuOpen: false,
            selected: [],
        };
    },
    watch: {
        isMenuOpen: function (visible) {
            if (visible) {
                this.$store.dispatch("messages/getMessages");
            }
        },
    },
    computed: {
        ...mapState("messages", ["messages"]),
        hasNotifications() {
            return true;
        },
        getFdate() {
            return (v) => {
                let date = new Date(v);
                return date.toLocaleDateString(this.loc);
            };
        },
    },
};
</script>

<style></style>
