import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#000",
                secondary: "#818383",
                accent: "#9c7ca7",
                error: "#6200EA",
            },
        },
    },
});
