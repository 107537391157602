<template>
    <div class="text-center">
        <v-dialog
            v-model="dialog"
            :fullscreen="isMobile"
            max-width="650"
            transition="dialog-bottom-transition"
            rounded="lg"
            scrollable
        >
            <v-card rounded="lg" elevation="0">
                <v-toolbar elevation="0" class="px-2 grey lighten-4">
                    <v-toolbar-title class="text-h5 my-2">
                        {{ $t("make_a_payout") }}
                    </v-toolbar-title>

                    <v-spacer />
                    <v-btn icon class="mr" @click="cancel" elevation="0">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-form v-model="formValid" ref="form">
                        <v-row class="my-2">
                            <v-col cols="6">
                                <vuetify-money
                                    v-model="mutableEditedItem.amount"
                                    :label="$t('payment_amount')"
                                    :options="moneyOptions"
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="12">
                                <vtx-text-field-iban
                                    v-model="mutableEditedItem.bankIban"
                                    label="IBAN"
                                    disabled
                                    hide-details
                                ></vtx-text-field-iban>
                            </v-col>
                            <v-col cols="12">
                                <vtx-text-field-iban
                                    v-model="mutableEditedItem.bankBic"
                                    label="BIC"
                                    disabled
                                    hide-details
                                ></vtx-text-field-iban>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn text @click="cancel">
                        {{ $t("cancel") }}
                    </v-btn>
                    <v-btn text @click="payout">
                        {{ $t("payout") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import VtxTextFieldIban from "@/components/bits/comp-vtx-text-field-iban";
import moment from "moment";

export default {
    components: { VtxTextFieldIban },

    props: {
        value: {
            type: Boolean,
            required: true,
        },
        editedItem: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            formValid: false,
            localRecord: {},
        };
    },

    beforeMount() {
        var locale = window.navigator.userLanguage || window.navigator.language;
        moment.locale(locale);
        this.loc = moment.localeData()._abbr;
    },

    computed: {
        isMobile() {
            return (
                this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.sm
            );
        },

        moneyOptions() {
            return {
                prefix: this.editedItem.currency,
                suffix: "",
                precision: 2,
                masked: true,
                length: 11,
                locale: this.loc,
            };
        },

        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },

        mutableEditedItem: {
            get() {
                return this.editedItem;
            },
            set(value) {
                console.log("emit");
                this.$emit("update:editedItem", value);
            },
        },
    },

    methods: {
        cancel() {
            this.dialog = false;
        },
        payout() {
            this.$emit("close-payout");
        },
    },
};
</script>
