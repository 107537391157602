<template>
    <v-dialog v-model="confirmDialog" max-width="600">
        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
                Please confirm your bid
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="text-body-1 mt-3">
                <strong>{{ $t("bid_confirmation_1") }}</strong
                >&nbsp; {{ $t("bid_confirmation_2") }}&nbsp;
                {{ bid.totalForFractions }} {{ bid.currency }} {{ $t("for") }}
                <strong>"{{ selected.title }}"</strong>
                {{ $t("bid_confirmation_3") }} {{ selected.authorName }},
                {{ $t("bid_confirmation_4") }} {{ bid.maxFractionPrice }}
                {{ bid.currency }}.
            </v-card-text>
            <v-card-text class="text-body-1">
                <strong
                    >{{ $t("bid_confirmation_5") }}&nbsp;{{
                        bid.totalForFractions
                    }}
                    {{ bid.currency }} + {{ bid.totalPercentFees }}% +
                    {{ $t("tax") }}
                    {{ $t("bid_confirmation_6") }}
                </strong>
            </v-card-text>
            <v-card-text class="text-body-1">
                {{ $t("bid_confirmation_7") }}
                <strong>{{ $t("bid_confirmation_8") }}</strong>

                <v-card
                    elevation="0"
                    class="mt-2 mb-0"
                    color="deep-orange lighten-1 text-center"
                    v-if="showBalanceAlert()"
                >
                    <v-card-text class="text-body-1">
                        <strong
                            >{{ $t("bid_confirmation_8") }}<br />
                            {{ $t("bid_confirmation_9") }}
                        </strong>
                    </v-card-text>
                </v-card>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn text @click="cancel">{{ $t("cancel") }} </v-btn>
                <v-btn text @click="confirmBid"> {{ $t("confirm") }} </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: [
        "selected", // auction object
        "bid", // bid object
        "walletBalance",
    ],
    data() {
        return {
            confirmDialog: false,
            resolve: null,
        };
    },

    methods: {
        open() {
            this.confirmDialog = true;
            return new Promise((resolve) => {
                this.resolve = resolve;
            });
        },
        confirmBid() {
            this.confirmDialog = false;
            this.resolve(true);
        },
        cancel() {
            this.confirmDialog = false;
            this.resolve(false);
        },
        showBalanceAlert() {
            if (
                this.walletBalance <
                this.bid.totalForFractions + this.bid.totalPercentFees
            ) {
                return true;
            } else {
                return false;
            }
        },
    },
};
</script>
